export default {
  pageNotFoundMessage: 'We didnt find the website, is the URL correct?',
  brandingMessage: 'by Code Yellow',
  flowmastersBranding: 'Supported by FlowMasters',
  navisionIntegration: {
    field: {
      company: {label: 'Company'},
      manageEndpointsHeader: {label: 'Endpoints'},
      item: {label: 'Items'},
      customer: {label: 'Customers'},
      jobs: {label: 'Jobs'},
      salesOrder: {label: 'Sales Order'},
      purchaseOrder: {label: 'Purchase Order'},
      productionOrder: {label: 'Production Order'},
    },
    purchaseOrderLine: {
      field: {
        requestedReceiptDate: 'Requested Date',
        promisedReceiptDate: 'Promised Date',
      }
    },
    overview: {
      syncButton: 'Sync'
    }
  },
  nav: {
    main: {
      operations: 'Operations',
      production: 'Supply',
      warehouse: 'Warehouse',
      planning: 'Planning',
      personnel: 'Personnel',
      assets: 'Masterdata',
      plantation: 'Plantation',
      lms: 'LMS',
      demand: 'Demand',
      handling: 'Handling',
    },
    audit: {
      accessLog: 'Event Log'
    },
    operations: {
      manager: 'Viewport',
      progress: 'Work Progress',
      batches: 'Batches',
      workStationSessions: 'Work Station Sessions',
      issues: 'Issues',
      simulation: 'Simulation',
    },
    sales: {
      salesOrders: 'Sales & Projects',
    },
    planning: {
      productionLines: 'Production Areas',
      scenario: 'Scenario',
      productionRequests: 'Workload',
      workStations: 'Daily Planning',
      day: 'Day Overview',
    },
    warehouse: {
      stock: 'Stock',
      inbound: 'Receive Orders',
      outbound: 'Pick Orders',
      purchaseOrder: 'Purchase Orders',
      warehouseTransfer: 'Transfer Orders',
    },
    personnel: {
      users: 'Users',
      operators: 'Operators',
      leaveCalendars: 'Leave Calendars',
      leaveSlots: 'Leave Requests',
    },
    assets: {
      processes: 'Processes',
      articleTypes: 'Article Types',
      exactItems: 'Exact Articles',
      productionLines: 'Production Areas',
      capabilities: 'Skills',
      loadCarriers: 'Loading Carriers',
      processPhases: 'Process Phases',
      dataSources: 'Data Sources',
      globalValues: 'Settings',
      templates: 'Templates',
      globalFiles: 'Special Files',
      integrations: 'Integrations',
      projects: 'Projects',
      factories: 'Locations',
      syncrequest: 'Sync Requests',
      metafields: 'Metafields',
    },
    plantation: {
      plantationLines: 'Plantation Areas',
      graphs: 'Graphs',
      scripts: 'Scripts',
      units: 'Units',
      plc: 'Machines',
      movements: 'Movements',
      overview: 'Overview',
      fertilizer: 'Fertilizer',
      experiments: 'Experiments',
    },
    lms: {
      map: 'Map',
      scenarios: 'Scenarios',
      sensors: 'Sensors',
      status: 'Status',
      alarms: 'Alarms',
      notifications: 'Notifications',
      templates: 'Notification templates',
    },
    account: {
      changelog: 'Changelog',
      clockInTime: 'Clocked in at {{clockInTime}}',
      notClockedIn: 'Not clocked in',
      clockIn: 'Clock In',
      clockOut: 'Clock Out',
      profile: 'Profile',
      logout: 'Logout',
      clockOutModal: {
        title: 'You are still clocked in.',
        message: 'Next to logging out, would you like to clock out as well?',
      },
    },
  },
  syncrequestoverview: {
    field: {
      id: {label: 'ID'},
      createdAt: {label: 'Created At'},
      message: {label: 'Message'}

    }
  },
  unit4requestoverview: {
    field: {
      id: {label: 'ID'},
      createdAt: {label: 'Created At'},
      message: {label: 'Message'}

    }
  },
  integrations: {
    field: {
      syncRequest: {label: 'Sync requests'},
      isOpen: {label: 'Show open tasks only'},
      unit4: {
        message: {value: 'Increase stock for item with product code {{productID}} with quantity: {{quantity}} '}
      },
      navision: {
        message: {value: 'Purchase order with id {{orderID}} can be marked as completed'}
      }
    },
    overview: {
      empty: 'There are no sync requests matching your filters.'
    }
  },
  profile: {
    title: 'Profile',
    changePassword: {
      label: 'Change Password',
      passwordOld: 'Current Password',
      passwordNew: 'New Password',
    },
  },
  unit: {
    overview: {
      title: 'Units'
    },
    field: {
      name: {
        label: 'Name'
      }
    }
  },
  plc: {
    field: {
      name: {label: 'Name'},
      type: {label: 'Type'},
      ipAddress: {label: 'IP Address'},
      heartbeat: {label: 'Connected'}
    },
    overview: {
      title: 'Machine overview',
      addButton: 'Machine'
    }
  },
  value: {
    field: {
      name: {label: 'Name'},
      register: {label: 'Register'},
      multiplicationFactor: {label: 'Multiplication factor'}
    }
  },
  user: {
    overview: {
      title: 'Users',
      empty: 'No users found.',
      addButton: 'User',
      convertToOperator: 'Convert to operator',
    },
    create: {
      title: 'Add {{fullName}}',
    },
    edit: {
      title: 'Edit {{fullName}}',
      saveBeforeLeaveSlot: 'You must first save this user before you can add leave.',
      tab: {
        info: 'Info',
        availability: 'Availability',
      },
    },
    field: {
      leaveBalances: { label: 'Leave Overview' },
      avatar: { label: 'Avatar' },
      fullName: { label: 'Name', unknown: 'User' },
      firstName: { label: 'First Name' },
      lastName: { label: 'Last Name' },
      email: { label: 'E-mail' },
      BSG: { label: 'BSG user' },
      password: { label: 'Password' },
      dateJoined: { label: 'Added' },
      manager: { label: 'Manager' },
      language: { label: 'User Language' },
      telegramUsername: { label: 'Telegram Username' },
      badgeId: { label: 'Badge ID' },
      groups: {
        label: 'Groups',
        value: {
          superuser: 'Super User',
          admin: 'Admin',
          manager: 'Manager',
          grower: 'Grower',
          rnd: 'R&D',
          stakeholder: 'Supplier',
          planner: 'Planner',
          'bsd-general': 'BSD General',
          'shift-lead': 'Shift Lead',
          'td': 'Technical Service',
          'supplier': 'Supplier',
          'bsd-script-mng': 'BSD Script Manager',
          'lms-admin': 'LMS Admin',
          'lms-bso-user': 'LMS BSO User',
          'lms-bsd-user': 'LMS BSD User',
          'lms-bso-mngr': 'LMS BSO Manager',
          'lms-bsd-mngr': 'LMS BSD Manager',
        },
        empty: 'No groups joined',
      },
      stakeholder: { label: 'Supplier' },
      availability: { label: 'Availability' },
      workSchedules: { label: 'Schedules' },
      leaveCalendars: { label: 'Leave Calendars' },
      extraLeaveBalances: {
        label: 'Remaining balance',
        empty: 'No remaining balance added',
      },
      isOperator: { label: 'Is Operator' },
      editOpenProductionOrder: {
        label: 'Can Edit Open Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
      releaseProductionOrder: {
        label: 'Can Release Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
      editReleasedProductionOrder: {
        label: 'Can Edit Released Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
    },
    login: {
      title: 'Log in',
      reloginMessage: 'You are logged out. Please login again.',
      forgotPasswordLink: 'Forgot password?',
      errors: {
        invalidCredentials: 'Email or password incorrect',
        unknown: 'Unknown error, status code: {{status}}',
      },
      loginButton: 'Log in',
      backButton: 'Go back',
      workStationStartButton: 'Start Work Station',
      operatorStartButton: ' Log in as Operator',
      or: 'Or',
    },
    passwordForgot: {
      title: 'Forgot Password',
      requestButton: 'Send Email',
      requestedEmailText: 'An email has been send to the correct address.',
    },
    resetPassword: {
      title: 'Password Reset',
      resetButton: 'Reset',
      saveSuccess: 'Password reset successful.',
    },
    activate: {
      title: 'Activate account',
      intro: 'Welcome to Tracy! You just need to set a password to activate your account.',
      activateButton: 'Activate account',
      saveSuccess: 'Your account is activated.',
    },
    changePassword: {
      title: 'Change password',
      field: {
        passwordOld: {
          label: 'Current password',
        },
        passwordNew: {
          label: 'New password',
        },
      },
      saveButton: 'Save',
    },
    account: {
      title: 'Profile',
      logoutButton: 'Log out',
      info: {
        title: 'Info',
      },
      password: {
        title: 'Change password',
      },
      logout: {
        title: 'Log out',
        confirm: 'Are you sure you want to log out?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Articles',
      empty: 'No articles found.',
      viewLink: '(bekijken)',
      actions: {
        label: 'Change pairing ({{count}})',
        action: {
          linkArticleTypes: 'Link ({{count}})',
          linkArticleTypes_plural: 'Link article types ({{count}})',
          unlinkArticleTypes: 'Unlink article types ({{count}})',
          unlinkArticleTypes_plural: 'Unlink article types ({{count}})',
        },
      },
    },
    edit: {
      title: 'Edit Article',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      classification: { label: 'Group' },
      isMakeItem: { label: 'Make' },
      isBuyItem: { label: 'Buy' },
      name: { label: 'Name' },
      articleType: { label: 'Article Type' },
      componentType: { label: 'Component Type' },
    },
  },
  operator: {
    home: {
      welcome: 'Welcome, {{firstName}}!',
      clockInTime: 'You clocked in at {{clockInTime}}.',
      notClockedIn: 'You are currently not clocked in.',
      availability: 'Availability',
      scheduled: {
        notScheduled: 'You are not scheduled on any work stations today.',
        title: 'Today you are scheduled on the following work stations:',
        times: '{{startAt}} - {{endAt}}',
      },
    },
    availability: {
      title: 'Availability',
    },
    logoutModal: {
      title: 'Logout',
      content: 'Are you sure you want to log out?',
      logoutButton: 'Logout',
    },
    start: {
      title: 'Log in as Operator',
      startButton: 'Log in',
    },
    overview: {
      title: 'Operators',
      empty: 'No operators found',
      addButton: 'Operator',
    },
    create: {
      title: 'Add {{name}}',
    },
    edit: {
      title: 'Edit {{name}}',
      saveBeforeLeaveSlot: 'You must first save this operator before you can add leave.',
      tab: {
        info: 'Info',
        capabilities: 'Skills',
        availability: 'Availability',
      },
      workScheduleDescription: `Schedule from {{startDate}}`,
    },
    field: {
      leaveBalances: { label: 'Leave Overview' },
      avatar: { label: 'Avatar' },
      fullName: { label: 'Name', unknown: 'Operator' },
      firstName: { label: 'First Name' },
      lastName: { label: 'Last Name' },
      operatorCapabilities: {
        label: 'Skills',
        empty: 'No skills added',
        none: 'No skills configured in the system.',
      },
      createdAt: { label: 'Added' },
      badgeId: { label: 'Badge ID' },
      workSchedules: {
        label: 'Schedules',
        empty: 'No schedules added',
      },
      availability: { label: 'Availability' },
      leaveCalendars: { label: 'Leave Calendars' },
      manager: { label: 'Manager' },
      hasPendingLeave: { label: 'Pending Leave Requests' },
      extraLeaveBalances: {
        label: 'Remaining balance',
        empty: 'No remaining balance added',
      },
      deleted: { label: 'Deleted' },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Event Log',
      json: {
        entries: '… {{count}} entry',
        entries_plural: '… {{count}} entries',
      },
      message: {
        showMore: 'Show more',
        showLess: 'Show less',
      },
      masqueradingAsUser: 'Masquerading as {{user}}',
      dateRange: {
        label: {
          today: 'Today',
          yesterday: 'Yesterday',
          date: 'Custom Date',
          daterange: 'Custom Range',
          alltime: 'All Time',
        },
        value: {
          alltime: 'All time',
          daterange: '{{start}} - {{end}}',
          timerange: '{{date}}, {{start}} - {{end}}',
          today: 'Today',
          yesterday: 'Yesterday',
          date: '{{date}}',
        },
        allDay: 'All Day',
        action: {
          cancel: 'Cancel',
          apply: 'Apply',
        },
      },
    },
    field: {
      originalUser: {
        label: 'Original user',
      },
      masqueradingAsUser: {
        label: 'Masquerading as user',
      },
      ipAddress: {
        label: 'IP address',
      },
      method: {
        label: 'Event Type',
        value: {
          POST: 'HTTP POST',
          PUT: 'HTTP PUT',
          PATCH: 'HTTP PATCH',
          DELETE: 'HTTP DELETE',
          ALARM: 'ALARM',
          MODBUS: 'MODBUS',
          NOTIFICATION: 'NOTIFICATION',
          NOTIFICATION_BUNDLE: 'NOTIFICATION BUNDLE',
        },
      },
      endpoints: {
        label: 'To',
      },
      statusCode: {
        label: 'Event',
      },
      statusString: {
        label: 'Event',
      },
      additionalInfo: {
        label: 'Message Details',
      },
      requestTime: {
        label: 'Time of Event',
      },
      destination: {
        label: 'To',
      },
      location: {
        label: 'Plantation Area',
      },
      origin: {
        label: 'From',
      },
    },
  },
  articleTypeWarehouse: {
    overview: {
      empty: 'No stock found',
    },
    field: {
      stock: { label: 'Stock', },
    },
    articleType: {
      id: 'ID',
      code: 'Code',
      classification: 'Group',
      name: 'Name',
    },
    warehouse: {
      name: 'Warehouse',
    },
    storageLocations: {
      field: {
        lines: { label: 'Storagelocations' },
        code: { label: 'Code' },
        default: { label: 'Default' },
        name: { label: 'Name' },
        stock: { label: 'Stock' },
      },
      noStorageLocation: 'No storage location specified.',
    },
  },
  salesOrder: {
    basicInfo: 'Details',
    overview: {
      title: 'Sales Orders',
      empty: 'No Sales Orders found.',
      responsibilityModal: {
        title: {
          edit: 'Edit Responsibility',
          add: 'Add Responsibility',
        },
      },
      allocated: {
        label: 'Allocated',
        value: 'Allocated: {{count}}/{{total}}',
      },
      ready: {
        label: 'Allocated Order(s)',
        value: 'Allocated Order(s): {{count}}/{{total}}',
      },
      inStock: {
        label: 'In Stock',
        value: 'In Stock: {{count}}/{{total}}',
      },
      planned: {
        label: 'Planned',
        value: 'Planned: {{count}}/{{total}}',
      },
      picked: {
        label: 'Picked',
        value: 'Picked: {{count}}/{{total}}',
      },
      shipped: {
        label: 'Shipped',
        value: 'Shipped: {{count}}/{{total}}',
      },
      delivered: {
        label: 'Delivered',
        value: 'Delivered: {{status}}',
      },
      invoiced: {
        label: 'Invoiced',
        value: 'Invoiced: {{status}}',
      },
      exactReturned: {
        label: 'Returned',
        value: 'Returned: {{count}}/{{total}}',
      },
      showMore: 'Show more ({{count}} line)',
      showMore_plural: 'Show more ({{count}} lines)',
      showLess: 'Show less',
      quantities: {
        projected: '# projected',
        planned: '# planned',
        warehouse: '# warehouse',
        required: '# required',
        allocated: '# allocated',
        available: '# Beschikbaar',
        expected: '# expected',
      },
    },
    edit: {
      title: 'Edit Sales Order',
      allocateButton: 'Allocate',
      stage: {
        progress: { label: 'Progress' },
        general: { label: 'General' },
        customer: { label: 'Customer' },
        dates: { label: 'Dates' },
        salesOrderLines: { label: 'Sales Order Lines' },
        pickOrders: { label: 'Pick Orders' },
        deliveryAddress: { label: 'Delivery Address' },
        invoiceAddress: { label: 'Invoice Address' },
        remarks: { label: 'Internal Notes' },
        responsibilities: { label: 'Responsibilities' },
      },
      allocateModal: {
        title: 'Allocate {{articleType}} ({{allocated}}/{{required}})',
        allocate: 'Allocate',
        source: {
          quantity: 'Quantity',
          unallocated: 'Available',
          allocated: 'Already Allocated',
          date: 'Due Date',
          quantityStatus: {
            label: 'Status',
            value: {
              open: 'Open',
              in_progress: 'In Progress',
              done: 'Done',
            },
          },
          hasAvailable: { label: 'Available' },
          hasAllocated: { label: 'Allocated' },
        },
      },
    },
    confirmDelete: 'Are you sure you want to delete this Sales Order?',
    field: {
      id: { label: 'ID' },
      erpId: { label: 'ERP ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Partial',
          complete: 'Completed',
          canceled: 'Canceled',
        },
      },
      information: { label: 'Information' },
      customer: { label: 'Customer' },
      reference: { label: 'Reference' },
      orderNumberErp: { label: 'Number' },
      orderDate: { label: 'Order date' },
      deliveryDate: { label: 'Delivery date' },
      deliveryAddress: { label: 'Delivery address' },
      remarks: { label: 'Remarks' },
      description: { label: 'Description' },
      outShipments: {
        label: 'Pick Orders',
        empty: 'No Pick Orders added yet.',
        value: 'Pick Order {{id}}',
      },
      lines: { label: 'Sales Order Lines' },
      quantity: { label: 'Quantity' },
      exactSalesOrder: { label: 'Sales Order' },
      warehouse: { label: 'Warehouse' },
      allocationStatus: {
        label: 'Status',
        value: {
          unallocated: 'Unallocated (completely or partially)',
          not_ready: 'All allocated but not ready',
          not_shipped: 'All ready but not shipped',
          all_shipped: 'All Shipped',
        },
      },
      stockStatus: {
        label: 'Stock Status',
        value: {
          no_stock: 'No stock present',
          partial: 'Partial stock present',
          complete: 'Complete stock present',
        },
      },
      priority: {
        label: 'Priority',
        value: {
          high: '1 - High',
          medium: '2 - Medium',
          low: '3 - Low',
        },
      },
      responsibilities: { empty: 'No responsibilities added yet.' },
      shippingMethod: { label: 'Shipping Method' },
    },
    filters: {
      todo: 'Todo',
      inProgress: 'In Progress',
      complete: 'Complete',
      overdueReceipts: 'Overdue (without work orders)',
      overdueRequests: 'Overdue (with work orders)',
      receiptsThisWeek: 'Planned this week',
      futureReceipts: 'Planned future',
      deleted: 'Deleted',
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Add',
    consumedLine: 'No more available quantity for the selected sales order line(s).',
    field: {
      id: { label: 'ID' },
      project: { label: 'Project' },
      productionDueDate: { label: 'Order end date' },
      deliveryDate: { label: 'Delivery date' },
      quantity: { label: 'Quantity' },
      _toReceive: { label: 'Te receive' },
      unitPrice: { label: 'Price per piece' },
      netPrice: { label: 'Net Price' },
      salesOrder: { label: 'Sales Order' },
      code: { label: 'Article Code' },
      erpLines: { label: 'ERP' },
      decimalQuantity: { label: 'Actual ERP Quantity'},
      description: { label: 'Description' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Number' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Partial',
          30: 'Completed',
          40: 'Canceled',
        },
      },
    },
  },
  purchaseOrder: {
    overview: {
      empty: 'No Purchase Orders found.',
    },
    confirmDelete: 'Are you sure you want to delete this Purchase Order?',
    filters: {
      status: 'Status',
      requestStatus: 'Work Order Status',
      ordersDueAndExpected: 'Orders due & expected',
      number: 'Number',
      todo: 'Todo',
      inProgress: 'In Progress',
      complete: 'Complete',
      overdueReceipts: 'Overdue (without work orders)',
      overdueRequests: 'Overdue (with work orders)',
      receiptsThisWeek: 'Planned this week',
      futureReceipts: 'Planned future',
      deleted: 'Deleted',
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Partial',
          complete: 'Complete',
          canceled: 'Canceled',
        },
      },
      supplier: { label: 'Supplier' },
      quantity: { label: 'Quantity' },
      reference: { label: 'Reference' },
      orderNumberErp: { label: 'Number' },
      orderDate: { label: 'Order Date' },
      receiptDate: { label: 'Receive Date', error: 'Receipt date cannot be empty.' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'License plate' },
      lines: { label: 'Purchase Order Lines' },
      inShipments: {
        label: 'Receive Orders',
        empty: 'No Receive Orders added yet.',
        value: 'Receive Order {{id}}',
      },
      exactPurchaseOrder: { label: 'Purchase Order' },
      warehouse: { label: 'Warehouse' },
      linkedSalesOrder: { label: 'Linked Sales Order ERP' },
      shippingMethod: { label: 'Shipping Method' },
    },
  },
  purchaseOrderLine: {
    noBatchTypeBuy: 'No batch type `buy` defined for this article',
    consumedLine: 'No more available quantity for the selected purchase order line(s).',
    addToInShipmentButton: 'Add',
    field: {
      id: { label: 'ID' },
      project: { label: 'Project' },
      productionRequest: { label: 'Work Order' },
      receiptDate: { label: 'Receive Date' },
      quantity: { label: 'Quantity' },
      code: { label: 'Article Code' },
      _toReceive: { label: 'To Receive' },
      unitPrice: { label: 'Unit Price' },
      netPrice: { label: 'Net Price' },
      purchaseOrder: { label: 'Purchase Order' },
      purchaseUnit: { label: 'Ordering Unit' },
      erpLines: { label: 'ERP' },
      decimalQuantity: { label: 'Actual ERP Quantity'},
      description: { label: 'Description' },
    },
  },
  inShipment: {
    disabledDelete: 'Cannot delete Receive Order because there are related Work Orders.',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Receive date' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'License plate' },
      shipperName: { label: 'Driver' },
    },
    edit: {
      title: 'Edit Receive Order',
    },
    missingReceiveProcessCreate: 'Create missing Receive Order process',
  },
  inShipmentLine: {
    disabledDelete: 'Cannot delete line because Work Order is in progress or completed',
    field: {
      id: { label: 'ID' },
      autoPerformedProductionRequest: { info: 'This line was added automatically because more articles were received than specified in the Purchase Order.' },
      inShipment: { label: 'Receive ID' },
    },
    create: {
      title: 'Create Receive Order Line',
    },
    edit: {
      title: 'Edit Receive Order Line',
    },
    missingBuyProcessWarning: 'No Inbound process found for this article type.',
    missingBuyProcessCreate: 'Create missing Inbound process',
  },
  outShipment: {
    disabledDelete: 'Cannot delete Pick Order because there are related Work Orders',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Pickup Date' },
      shipperName: { label: 'Driver' },
      shipperLicensePlate: { label: 'License Plate' },
      freightType: { label: 'Cargo type' },
      freightCompany: { label: 'Transport Company' },
      freightInfo: { label: 'Cargo Info' },
      outShipmentTemplate: { label: 'Packing Slip Template' },
    },
    missingPickProcessCreate: 'Create missing Pick Order process',
  },
  outShipmentLine: {
    disabledDelete: 'Cannot delete line because Work Order is in progress or completed.',
    field: {
      id: { label: 'ID' },
      outShipment: { label: 'Pick ID' },
    },
    create: {
      title: 'Create Pick Order',
    },
    edit: {
      title: 'Edit Pick Order',
    },
    missingSellProcessWarning: 'No Outbound process found for this article type.',
    missingSellProcessCreate: 'Create missing Outbound process',
  },
  capability: {
    overview: {
      title: 'Skills',
      empty: 'No skills found.',
      addButton: 'Skill',
    },
    create: {
      title: 'Add Skill',
    },
    edit: {
      title: 'Edit Skill',
    },
    field: {
      name: { label: 'Name' },
      operatorCapabilities: {
        label: 'Operators',
        none: 'No operators configured.',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Process Steps',
      empty: 'No process steps found.',
      addButton: 'Process Step',
    },
    create: {
      title: 'Create Process Phase',
    },
    edit: {
      title: 'Edit Process Phase',
    },
    field: {
      icon: { label: 'Icon' },
      name: { label: 'Name' },
    },
  },
  batchType: {
    overview: {
      title: 'Processes',
      empty: 'No processes found.',
    },
    create: {
      title: 'Add Process',
    },
    onTheFly: {
      title: 'On the Fly Settings',
      description: "With 'On-the-Fly' Tracy can automatically create a new batch when a serial number is scanned which isn't known yet. The entered serial number has to match the serial format.",
    },
    edit: {
      title: 'Edit Process',
      bulkTemplateButton: 'Bulk Add Template',
      bulkTemplateModal: {
        title: 'Bulk Add Template',
        selectButton: 'Add to {{count}} Article Type',
        selectButton_plural: 'Add to {{count}} Article Types',
        selectAllButton: 'Add to all {{count}} Article Types',
      },
      bulkTemplateProgressModal: {
        title: 'Bulk Add Progress',
        progress: '{{count}} of {{total}} added.',
        timeRemaining: '{{time}} remaining.',
      },
      finalizeProgressModal: {
        title: 'Finalizing process template progress',
      },
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      quantity: { label: 'Batchsize' },
      name: { label: 'Batchsize' },
      articleType: { label: 'Article Type' },
      onTheFlySerialNumberFormat:{label: 'On the Fly Serial Number Format'},
      type: {
        label: 'Type',
        value: {
          make: 'Make',
          buy: 'Inbound',
          sell: 'Outbound',
          transfer_line: 'Transfer line',
          component: 'Component',
          subassembly: 'Subassembly',
          stock_count: 'Stock Count',
          stock_count_template: 'Stock Count Template',
          transfer: 'Transfer',
          on_the_fly: 'On the Fly',
          pick_order: 'Pick Order',
          receive_order: 'Receive Order',
          make_template: 'Make Template',
          buy_template: 'Inbound Template',
          sell_template: 'Outbound Template',
          transfer_line_template: 'Transfer Line Template',
        },
      },
      serialNumberFormat: { label: 'Serial Format' },
      default: { label: 'Default' },
      source: { label: 'Template' },
      targets: {
        label: 'Article Types',
        value: 'Used in {{count}} article type.',
        value_plural: 'Used in {{count}} article types.',
      },
      onTheFlyType: {
        label: 'On the Fly Batch Size',
        info: 'This setting determines which size the batches will be when they are created on the fly.',
        value: {
          none: 'None',
          fixed: 'Fixed',
          flexible: 'Flexible',
        },
        tooltip: {
          none: 'If you select this option we will not actually look at the quantity of the created batch, it can be used infinitely many times.',
          fixed: 'If you select this option you will have to enter a fixed size for the created batches.',
          flexible: 'If you select this option the operator will be prompted to enter the size of the batch when a new batch has to be created.',
        },
      },
      onTheFlySource: {
        label: 'On the Fly Source',
        info: 'This setting determines where the batch that will be created on the fly comes from.',
        value: {
          new: 'New',
          existing: 'Existing',
        },
        tooltip: {
          new: 'A new batch will be created.',
          existing: 'The system will prompt to scan an existing batch that the new batch will be taken from as a subbatch.',
        },
      },
      autoAddProcessToNewArticles: { label: 'Auto add process to new synced article types' },
    },
  },
  articleType: {
    overview: {
      title: 'Article Types',
      empty: 'No Article Types found.',
      addButton: 'Article Type',
      viewBOMButton: 'View BOM',
      copyBatchTypeButton: 'Copy Batch Types',
    },
    printShelfLabelButton: 'Stock Location Label',
    selectModal: {
      title: 'Choose Article Type',
    },
    create: {
      title: 'Add Article Type',
    },
    edit: {
      baseFields: {
        label: 'Basic Fields',
        info: 'These fields do not belong to a process type, but apply to the entire article.',
      },
      title: 'Edit Article',
      version: 'Version {{version}}',
      draft: 'Concept',
      finalizeButton: 'Finalize',
      nextVersionButton: 'Next Version',
      noStepSelected: 'No step selected',
      workStationsModal: {
        title: 'Select Work Stations',
      },
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied successfully.',
        pasted: 'Pasted successfully.',
        pasteError: 'Nothing to paste.',
      },
      bomFieldsModal: {
        title: 'BOM Fields',
        content: 'This process contains form fields of type \'BOM\'. Switching to another batch type than \'make\' will cause these fields to be deleted.',
      },
      endWithVariableQuantity: 'The process cannot end with a variable quantity. Add a quantity form field to define the actual batch size.',
      splitWithVariableQuantity: 'A batch step cannot be used on a variable quantity.',
      quantityFieldWithoutVariableQuantity: 'A quantity field cannot be used without a variable quantity.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Name' },
      line: { label: 'Production Area' },
      versions: { label: 'Versions' },
      batchTypes: { label: 'Processes' },
      articleType: { label: 'Article Type' },
      componentType: { label: 'Component Type' },
      storageLocations: { short: 'Storage Location(s)', long: 'Storage Location(s) - Quantity' },
      classification: { label: 'Group' },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
      isMakeOrBuy: { label: 'Make/Buy' },
      isMake: { label: 'Make' },
      isBuy: { label: 'Buy' },
      availableBatches: {
        label: 'Available Batch(es)',
        value: '{{count}} available batch',
        value_plural: '{{count}} available batches',
        noBatch: 'No available batch',
      },
      isSerial: { label: 'Serial' },
      totalStock: { label: '# Available' },
      storageLocationStock: { label: 'Storage Location Stock' },
      deleted: { label: 'Deleted' },
      endDateBeforeCurrDate: {label: 'Show end-date before today'},
      trackBatchUsage: { label: 'Track batch usage'},
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Article Type' },
      productionLineVersion: { label: 'Production Area Version' },
    },
  },
  plantationLine: {
    overview: {
      title: 'Plantation Areas',
      empty: 'No plantation area found.',
      addButton: 'Plantation Area',
      select: {
        type: {
          overview: 'R&D Overview',
          multilayer: 'Multilayer ({{count}})',
          highwire: 'Highwire ({{count}})',
          'rnd multilayer': 'R&D Multilayer ({{count}})',
          'rnd highwire': 'R&D Highwire ({{count}})',
        },
      },
      details: {
        location: 'R{{rack}}L{{layer}}P{{position}}',
        row: 'R{{rack}}L{{layer}}',
        tank: 'T{{tank}}',
        rack: 'R{{rack}}',
        group: 'Group {{group}}',
      },
      areaPosition: {
        viewGraph: "View graph",
        labels: {
          openTime: 'Open time (s)',
          cycleNumber: 'Frequency (h)',
          setpointLight: 'Intensity setpoint (μmol/m²/s)',
          feedbackLight: 'Intensity feedback (μmol/m²/s)',
          tableNumber: 'Table Number',
        }
      },
      history: {
        title: 'History: {{subject}}',
        start: 'Start',
        end: 'End',
      },
      rack: { label: 'Rack', value: '{{rack}}' },
      location: { label: 'Position', value: 'R{{rack}}L{{layer}}P{{position}}' },
      container: { label: 'Container' },
      scripts: {
        noneSelected: 'None Selected',
        subScript: 'Phase {{n}}',
        hasSchedule: 'Has a Day Schedule',
        hasMultiDay: 'Has Multiple Days Scheduled',
        label: 'Values',
        setpoint: {
          name: 'Name',
          value: 'Setpoint',
          current: 'Current',
          actual: 'Actual',
        },
      },
      production: 'Production',
      moveUp: 'Move Up',
      moveDown: 'Move Down',
      rndOverview: {
        chamberOverview: 'Chamber Overview',
        goToChamber: 'Go to Chamber {{chamber}}',
        note: 'Note: This page shows Feedback values only (for now). To check if they are in setpoint range, go to the individual chamber pages.',
        labels: {
          evaporation: 'Evaporation',
          co2: 'CO₂',
          tIn: 'T in',
          tOut: 'T out',
          xIn: 'X in',
          xOut: 'X out',
          speed: 'Velocity',
          humidity: 'Humidity',
          ec1: 'EC 1',
          ec2: 'EC 2',
          ph1: 'pH 1',
          ph2: 'pH 2',
          tantT: 'Temperature',
          ahu1: 'R&D AHU 1',
          ready1: 'R&D Ready 1'
        },
        units: {
          evaporation: 'g/m²⋅h',
          co2: 'ppm',
          tIn: '°C',
          tOut: '°C',
          xIn: 'g/kg',
          xOut: 'g/kg',
          speed: 'cm/s',
          humidity: '%',
          ec1: 'mS/cm',
          ec2: 'mS/cm',
          ph1: '',
          ph2: '',
          tantT: '°C',
        },
      },
    },
    create: {
      title: 'Add Plantation Area',
    },
    edit: {
      title: 'Edit Plantation Area',
      planning: {
        empty: 'No scripts scheduled yet.',
        current: 'Current',
        next: 'Next',
        planned: 'Planned ({{count}})',
        modal: {
          title: {
            add: 'Add Script Allocation',
            edit: 'Edit Script Allocation',
          },
        },
        overrides: {
          label: 'Overrides',
        },
        confirm: {
          groupDelete: {
            message: 'Are you sure you want to delete this group?',
            action: 'Delete',
          },
          overrideDelete: {
            message: 'Are you sure you want to delete this override?',
            action: 'Delete',
          },
          cancelChanges: {
            message: 'You have unsaved changes, do you really want to close?',
            action: 'Close',
          },
        },
      },
      tank: {
        label: {
          highwire: 'HW ready {{tank}}',
          multilayer: 'ML ready {{tank}}',
          'rnd highwire': 'R&D ready {{tank}}',
          'rnd multilayer': 'R&D ready {{tank}}',
          factory: 'Premix {{tank}}',
        },
        ec1: 'EC 1 (mS/cm)',
        ph1: 'pH 1',
        ec2: 'EC 2 (mS/cm)',
        ph2: 'pH 2',
        tankTemp: 'Current Tank Temperature',
      },
      ahu: {
        label: {
          highwire: 'HW AHU {{ahu}}',
          multilayer: 'ML AHU {{ahu}}',
          'rnd highwire': 'R&D AHU {{ahu}}',
          'rnd multilayer': 'R&D AHU {{ahu}}',
        },
        evaporation: 'Evaporation (g/m²⋅h)',
        tIn: 'T in (°C)',
        tOut: 'T out (°C)',
        xIn: 'X in (g/kg)',
        xOut: 'X out (g/kg)',
        co2: 'CO₂ (ppm)',
        speed: 'Velocity (cm/s)',
        humidity: 'Humidity (%)',
      },
      graph: {
        search: 'Search',
        selectAll: 'Select All',
        unselectAll: 'Unselect All',
        ahu: {
          label: 'AHU',
          value: 'AHU {{ahu}}',
          field: {
            FB_t_in: 'T in',
            FB_evaporation: 'Evaporation',
            FB_speed_cm: 'Speed',
            FB_co2_ML: 'CO₂',
          },
          unit: {
            FB_t_in: '°C',
            FB_evaporation: 'g/m²⋅h',
            FB_speed_cm: 'm³/h',
            FB_co2_ML: 'ppm',
          },
        },
        tank: {
          label: 'Tank',
          value: 'Tank {{tank}}',
          field: {
            FB_ec: 'EC',
            FB_ph: 'pH',
          },
          unit: {
            FB_ec: 'mS/cm',
            FB_ph: '',
          },
        },
        light: {
          label: 'Light',
          value: 'R{{rack}}L{{layer}}P{{position}}',
          field: {
            FB_intensity: 'Intensity'
          },
          unit: {
            FB_intensity: 'mA'
          },
        },
        scope: {
          hour: 'Last Hour',
          day: 'Last Day',
          week: 'Last Week',
          month: 'Last Month',
        },
      },
    },
  },
  productionLine: {
    overview: {
      title: 'Production Areas',
      empty: 'No production area found.',
      addButton: 'Production Area',
    },
    planning: {
      title: 'Production Areas',
      empty: 'No production area found.',
      productionLine: 'Production Area',
      week: 'Week',
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      parts: {
        night: 'Night (00:00 - 06:00)',
        morning: 'Morning (06:00 - 12:00)',
        afternoon: 'Afternoon (12:00 - 18:00)',
        evening: 'Evening (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Add Production Area',
    },
    edit: {
      baseFields: {
        label: 'Global Settings',
        info: 'These fields do not belong to a version but are applicable to every version.',
      },
      title: 'Edit Production Area',
      version: 'Version {{version}}',
      draft: 'Concept',
      finalizeButton: 'Finalize',
      nextVersionButton: 'Next version',
      tabs: {
        map: 'Map',
        codes: 'Codes',
        availability: 'Availability',
      },
    },
    selectModal: {
      title: 'Select a production area.',
    },
    codesModal: {
      title: 'Codes Overview',
      noPrintersAvailable: 'No printers available.',
    },
    field: {
      name: { label: 'Name' },
      fullName: { label: 'Name' },
      versions: { label: 'Versions' },
      timezone: { label: 'Timezone' },
      cultivationArea: { label: 'Cultivation Area  (m2)' },
      color: {
        label: 'Colour',
        value: {
          red: 'Red',
          orange: 'Orange',
          yellow: 'Yellow',
          olive: 'Olive green',
          green: 'Green',
          teal: 'Turquoise',
          blue: 'Blue',
          violet: 'Violet',
          purple: 'Purple',
          pink: 'Pink',
          brown: 'Brown',
          grey: 'Gray',
          black: 'Black',
        },
      },
      workSchedules: {
        label: 'Schedules',
        errors: {
          missing: 'Production line must have a valid schedule.',
        }
      },
      leaveCalendars: { label: 'Leave Calendars' },
      factory: { label: 'Location' },
      type: {
        label: 'Type',
        value: {
          production: 'Production',
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          'rnd multilayer': 'R&D Multilayer',
          'rnd highwire': 'R&D Highwire',
        },
      },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Version' },
      draft: { label: 'Concept' },
      floorPlan: { label: 'Layout' },
      workStations: {
        label: 'Work Stations',
        addButton: 'Work Station',
        error: {
          missing: 'At least one work station is required.',
        }
      },
      productionLine: { label: 'Production Area' },
    },
  },
  workSchedule: {
    field: {
      employer: { label: 'Employer', placeholder: 'Start typing to search or add employers' },
      startDate: { label: 'Start Date' },
      workSlots: {
        label: 'Work Times',
        empty: 'No work times have been added yet.',
      },
      allowedLegalLeaveMinutes: { label: 'Legal Vacation Minutes' },
      allowedExtraLeaveMinutes: { label: 'Extra Vacation Minutes' },
      allowedRwhMinutes: { label: 'RWH-days' },
    },
  },
  workStation: {
    field: {
      name: { label: 'Name' },
      code: { label: 'Code' },
      dataSources: { label: 'Data Source' },
      defaultFte: { label: 'Standard Staff' },
      singleUser: {
        label: 'Login Behaviour',
        value: {
          true: 'Single Login',
          false: 'Multi Login',
        },
        tooltip: {
          true:
            "When set as 'Single Login' The operator is asked to scan the badge once. By default, after 4h the station will auto log off. This time can be changed by changing the variable single_user_work_station_timeout_minutes in the general settings.",
          false: "When set as 'Multi Login' The operator is asked to scan the badge every step.",
        },
      },
      productionStrategy: {
        label: 'Production strategy',
        value: {
          together: 'Together',
          seperate_spread: 'Separate Spread',
          seperate_planned: 'Separate Planned',
        },
        tooltip: {
          together: "When set as 'Together', the stations will act as 1 station",
          seperate_spread: "When set as 'Separate Spread', the stations will work separately on the same orders.",
          seperate_planned: "When set as 'Separate Planned', the stations will work on different orders as planned.",
        },
      },
      maintenanceStrategy: {
        label: 'Maintenance Strategy',
        value: {
          together: 'Together',
          seperate: 'Separate',
        },
        tooltip: {
          together: "When set as 'Together', maintenance will be performed for all locations at a time.",
          seperate: "When set as 'Separate', maintenance will be planned and performed for each location separately.",
        },
      },
      steps: { label: 'Steps' },
      automationDate: { label: 'Automation Date' },
      work: { label: 'Work' },
      workAssigned: { label: 'Assigned' },
      workUnassigned: { label: 'Unassigned' },
      productionRequests: { label: 'Work Orders' },
    },
    start: {
      title: 'Login Work Station',
      startButton: 'Start',
    },
    production: {
      title: 'Production',
      empty: 'No orders could be found.',
      stopButton: 'Stop',
      selectButton: 'Perform',
      version: 'Version {{version}}',
      operatorModal: {
        title: 'Scan Badge ID',
        confirmButton: 'Confirm',
        error: {
          noResults: 'Badge-ID not found.',
          emptyField: 'Enter the Badge-ID of the operator.',
          multipleResults: 'Multiple operators found with same Badge ID.',
          unexpected: 'A unexpected error occured.',
          missingCapabilities: 'Incorrect skills: {{capabilities}}.',
          missingCapabilities_plural: 'The found operator does not have the correct skills: {{capabilities}}.',
        },
      },
      batchModal: {
        title: 'Scan Product',
        confirmButton: 'Confirm',
        error: {
          notFound: 'No product found with this serial number.',
        },
      },
      performModal: {
        generalErrors: 'Some errors occured.',
        title: 'Perform Step',
        confirmButton: 'Confirm',
        formStep: {
          label: 'Serialnumber',
          subject: {
            article_type: 'Article',
          },
        },
        printStep: {
          selectPrinter: 'Select Printer',
          noPrintersAvailable: 'No Printers Available',
          printButton: 'Print',
          printedSuccesfully: 'Printed successfully',
        },
        splitStep: {
          printButton: 'Print',
          printAllButton: 'Print All ({{count}})',
          reprintButton: 'Reprint',
          reprintButton_plural: 'Reprint ({{count}})',
          bulkEntrySerialNumbersDetail: 'You can scan multiple serial numbers at once.',
          wrongFormat: 'The serial number {{serialNumber}} does not match the predetermined format',
          expectedQuantity: 'You already scanned the expected number of serial numbers.',
          integrationNotActive: 'Exact integration is not activated or does not exist.',
          alreadySyncedToExact: 'The serial number {{serialNumber}} has already been scanned.',
        },
        quantityType: {
          quantity: 'Quantity',
          weight: 'Weight',
          error: {
            tooMuch: 'The quantity provided is higher than the quantity left for this order.',
          }
        },
        scan: {
          serialNumber: 'Serial Number',
          quantity: 'Quantity',
          error: {
            notFound: 'No batch found with the provided serial number.',
            wrongArticleType: 'The batch found with the provided serial number does not match the expected article type.',
            wrongBatchType: 'The batch found with the provided serial number is not of a batch type that can be scanned.',
            fullyUsed: 'The batch found with the provided serial number does not have any stock remaining.',
          },
        },
      },
      quantityModal: {
        title: 'Enter Batch Quantity',
        quantity: 'Quantity',
        confirmButton: 'Confirm',
      },
      infoModal: {
        title: 'Info',
      },
      fastForwardButton: 'Fast Forward',
      fastForwardModal: {
        title: 'Fast Forward',
        days: 'Days',
        confirmButton: 'Confirm',
      },
      operator: {
        expiresAt: 'Ends at{{at}}',
      },
      onTheFlyQuantityModal: {
        title: 'On the Fly Quantity',
        description: 'The scanned batch does not exist yet in the system. In order to create it on the fly the system needs to know its quantity.',
        placeholder: 'Quantity',
      },
      onTheFlySourceModal: {
        title: 'On the Fly Source',
        description: 'The scanned batch does not exist yet in the system. In order to create it on the fly the system needs to know the source batch it was taken from.',
        placeholder: 'Serial Number',
        error: {
          notFound: 'No batch found with the provided serial number.',
          wrongArticleType: 'The batch found with the provided serial number does not match the expected article type.',
          notEnough: 'The batch found with the provided serial number does not have enough stock remaining.',
        },
      },
    },
    editModal: {
      editTitle: 'Configure Work Station',
      viewTitle: 'Work Station Configuration',
    },
    stopModal: {
      title: 'End Work Station',
      content: 'Are you sure you want to end this Work Station?',
      stopButton: 'Stop',
    },
    planning: {
      title: 'Daily Planning',
      empty: 'Nothing to plan for this day.',
      assignmentsModal: {
        title: 'Daily Planning: {{name}}',
      },
      assignmentTimeModal: {
        title: 'Change Times',
        overlap: {
          label: 'These times overlap with some already assigned slots for this operator.',
          assignment: '{{workStation}} from {{start}} until {{end}}.',
        },
      },
      day: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        other: 'Other',
      },
      stats: {
        work: 'Hours of work',
        workAssigned: 'Assigned hours',
        workUnassigned: 'Unassigned hours',
        month: {
          january: 'JANUARY',
          february: 'FEBRUARY',
          march: 'MARCH',
          april: 'APRIL',
          may: 'MAY',
          june: 'JUNE',
          july: 'JULY',
          august: 'AUGUST',
          september: 'SEPTEMBER',
          october: 'OCTOBER',
          november: 'NOVEMBER',
          december: 'DECEMBER',
        },
      },
    },
    day: {
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
  },
  productionRequest: {
    registerExistingButton: 'Stock Count',
    overview: {
      title: 'Workload',
      empty: 'No work orders could be found',
      addButton: 'Production Order',
      productionRequestID: 'Work Order ID',
      inShipmentId: 'Receive Order ID',
      outShipmentId: 'Pick Order ID',
      exactSalesOrder: 'Open Sales Order {{order}} in Exact',
      exactPurchaseOrder: 'Open Purchase Order {{order}} in Exact',
      exactShopOrder: 'Open Production Order {{order}} in Exact',
      purchaseOrderNumber: 'Purchase Order ID',
      stockCountNumber: 'Stock Count ID',
      salesOrderNumber: 'Sales Order ID',
      linkedSalesOrder: 'Linked Sales Order ERP',
      warehouseTransferID: 'Warehouse Transfer ID',
      warehouseTransferLineID: 'Warehouse Transfer Line ID',
      productionOrderNumber: 'Production Order ID',
      orders: 'Orders',
      done: '{{count}} product done',
      done_plural: '{{count}} products done',
      progress: '{{percentage}} of work done',
      extra: '+ {{count}} extra',
      extra_plural: '+ {{count}} extra',
      diff: {
        planned: 'Planned',
        actual: 'Actual',
        advance: 'Advance',
        delay: 'Delay',
      },
      information: 'Information',
      order: 'Order',
      processSteps: 'Process steps',
    },
    edit: {
      title: 'Edit Work Order',
      bom: 'BOM',
      storageLocations: 'STORAGE LOCATIONS',
      releaseButton: 'Release',
      stage: {
        articleType: {
          label: 'Article Type Info',
          bom: {
            notEnoughStock: "Not enough stock available to produce this quantity",
          },
          showBomBtn: "Show BOM",
        },
        warehouse: {
          label: 'Warehouse Info',
        },
        planning: {
          label: 'Planning',
          planned: 'Planned',
          actual: 'Actual',
          duration: 'Duration',
          start: 'Start',
          end: 'End',
        },
        bom: {
          label: 'BOM List',
          articleCode: 'Article Type Code',
          articleName: 'Article Type Name',
        },
        productionLines: {
          label: 'Production Areas',
        },
      },
      couldNotAllocate: {
        header: 'Could not allocate a {{content}} container on {{date}} because:',
        row: 'R{{rack}}L{{layer}}',
        content: {
          seeded: 'seeded',
          transplanted: 'transplanted',
          spaced_1: 'spaced 1',
          spaced_2: 'spaced 2',
        },
        reason: {
          no_space: 'No space left for container.',
          not_fifo: 'Container has to leave the row at {{allocation_date}}, but it would be behind a container that leaves at {{row_date}}.',
          wrong_nutrition_script: 'Container needs the nutrition script {{allocation_script}}, but {{row_script}} is running in the row.',
          wrong_irrigation_script: 'Container needs the irrigation script {{allocation_script}}, but {{row_script}} is running in the row.',
          wrong_light_script: 'Container needs the light script {{allocation_script}}, but {{row_script}} is running in the row.',
        },
      },
    },
    field: {
      id: { label: 'Work Order ID' },
      articleType: { label: 'Article Type' },
      processVersion: { label: 'Version' },
      week: { label: 'Week' },
      quantity: { label: 'Quantity' },
      progress: { label: 'Progress' },
      startAt: { label: 'Start' },
      endAt: { label: 'End' },
      duration: { label: 'Duration' },
      project: { label: 'Project' },
      finished: { label: 'Finished' },
      productionOrder: { label: 'Production Order' },
    },
    filter: {
      itemGroup: 'Item Group',
      requestStatus: 'Work Order Status',
      inShipmentId: 'Receive ID',
      outShipmentId: 'Pick ID',
      warehouseTransferID: 'Transfer ID',
      projectCode: 'Project Code',
      purchaseOrderNumberERP: 'Purchase Order ERP',
      salesOrderNumberERP: 'Sales Order ERP',
      shippingMethod: 'Shipping Method',
    },
    addModal: {
      title: 'Add Order',
      step: {
        pickWeek: 'Select week',
        pickArticleTypes: 'Select article type',
        setQuantities: 'Select quantity',
      },
      quantityWarning: 'Amending the order quanitity to 0 is equal to deleting the order.',
      noArticleTypes: 'No article types selected.',
      notFullyDeleted: 'The order could not be deleted because it is in progress.',
    },
    editModal: {
      title: 'Edit Order',
      title_plural: 'Edit Orders',
    },
  },
  myFilter: {
    custom: 'New',
    action: {
      unsetDefault: 'Use as standard',
      setDefault: 'Use as standard',
      setCurrent: 'Save current filters',
      delete: 'Delete',
    },
  },
  globalValue: {
    edit: {
      unsetButton: 'Unset',
      title: 'Edit {{key}}',
      operator_badge_print: {
        key: {
          operator_name: 'The operator\'s name.',
          badge_id: 'The operator\'s badge ID.',
        },
      },
      workstation_code_print: {
        key: {
          workstation_name: 'The work station\'s name.',
          workstation_code: 'The work station\'s code.',
        },
      },
      stock_location_print: {
        key: {
          article_type_code: 'The article type\'s code.',
          article_type_name: 'The article type\'s name.',
          warehouse_code: 'The warehouse\'s name.',
          warehouse_locations: 'The warehouse\'s locations.',
        },
      },
      loading_carrier_print: {
        key: {
          loading_carrier_serial: 'Serial number of the loading carrier',
          loading_carrier_name: 'Name of the loading carrier',
        },
      },
      login_url_print: {
        key: {
          slug: 'The slug of your tracy instance. ({{value}})',
          app_url: 'The base url of tracy. ({{value}})',
        },
      },
      progress_scope: {
        week: 'Week',
        month: 'Month',
        day: 'Day',
      },
    },
    overview: {
      title: 'Global settings',
      template: {
        empty: 'Template is empty',
        moreLines: '{{count}} more line hidden, hover to show.',
        moreLines_plural: '{{count}} more lines hidden, hover to show.',
      },
    },
    field: {
      key: {
        label: 'Setting name',
      },
      value: {
        label: 'Value',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  template: {
    field: {
      name: { label: 'Name' },
      file: { label: 'PDF' },
      type: {
        label: 'Type',
        value: {
          out_shipment: 'Packing Slip',
        },
      },
      isDefault: { label: 'Default' },
    },
    overview: {
      title: 'Templates',
      empty: 'No templates have been added',
      addButton: 'Template',
    },
    create: {
      title: 'Create Template',
    },
    edit: {
      title: 'Edit Template',
    },
    info: 'In a template you can add product variables by typing {{ and selecting the correct value.',
  },
  globalFile: {
    edit: {
      title: 'Edit special file',
    },
    overview: {
      title: 'Special files',
    },
    field: {
      key: {
        label: 'Name for file',
      },
      value: {
        label: 'File',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  tooltips: {
    masquerade: 'Masquerade as',
    edit: 'Edit',
    delete: 'Delete',
    restore: 'Restore',
    view: 'View',
    select: 'Select',
    close: 'Close',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Add field',
    },
    field: {
      fields: {
        label: 'Fields',
        empty: 'The form is empty',
      },
    },
    // Form stuff
    cap: {
      remaining: '{{count}} remaining',
      tooMany: '{{count}} too many',
    },
    yes: 'Yes',
    no: 'No',
    either: 'Both',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'No icon selected',
    },
    applyButton: 'Apply',
    confirmButton: 'Confirm',
    continueButton: 'Continue',
    cancelButton: 'Cancel',
    deleteButton: 'Delete',
    restoreButton: 'Restore',
    backButton: 'Back',
    nextButton: 'Next',
    submitButton: 'Submit',
    saveButton: 'Save',
    saveAndBackButton: 'Save & Back',
    addNote : 'Leave a note',
    addANoteNotification: 'You’ve changed one or more setpoints. Please add a note before saving.',
    downloadButton: 'Download',
    copyButton: 'Copy',
    addButton: 'Add',
    editButton: 'Edit',
    printButton: 'Print',
    clearButton: 'Clear',
    exportButton: 'Export',
    viewButton: 'View',
    bulkActionButton: 'Action ({{count}})',
    confirmQuestion: 'Are you sure you want to continue?',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Other',
      none: 'No file uploaded yet',
    },
    multiPick: {
      searchPlaceholder: 'Select…',
      selectedText: '$1 from $2 selected',
      noneSelectedText: 'None selected',
      selectAllButton: 'All',
      selectNoneButton: 'None',
    },
    notifications: {
      saveSuccess: 'Saved successfully',
      deleteSuccess: 'Deleted successfully',
      saveError: 'Error with saving ({{status}})',
      saveValError: 'Not all information is filled in correctly',
      saveAuthError: 'Invalid credentials',
      newAppVersion: 'New version available, click to reload page as soon as possible',
    },
    startDate: 'Start date',
    endDate: 'End date',
    deleteConfirmation: 'Are you sure you want to delete {{name}} ?',
    repeat: 'Repeat',
    greaterThan: 'Higher than',
    lowerThan: 'Lower than',
    greaterThanOrEqual: '>=',
    lowerThanOrEqual: '<=',
    duration: {
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
    },
  },
  crash: {
    heading: 'Application has crashed',
    subHeading1: 'We appologize, the application has crashed',
    subHeading2: 'Our team is notified.',
    reload: 'Reload page',
  },
  permissions: {
    heading: 'No user groups',
    subHeading1: 'Your account is not linked to a group.',
    subHeading2: 'Ask your manager to give you the correct permissions.',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Select icon',
    filterPlaceholder: 'Search...',
    category: {
      favorites: 'Favorites',
      accessibility: 'Accessibility',
      arrows: 'Arrows',
      audioVideo: 'Audio/Video',
      business: 'Business',
      chess: 'Chess',
      code: 'Code',
      communication: 'Communication',
      computers: 'Computers',
      currency: 'Currency',
      dateTime: 'Date/Time',
      design: 'Design',
      editors: 'Editing',
      files: 'Files',
      genders: 'Genders',
      handsGestures: 'Hand signatures',
      health: 'Health',
      images: 'Pictures',
      interfaces: 'Interfaces',
      logistics: 'Logistics',
      maps: 'Cards',
      medical: 'Medical',
      objects: 'Objects',
      paymentsShopping: 'Shopping',
      shapes: 'Shapes',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'People',
      vehicles: 'Vehicles',
      writing: 'Writing',
    },
  },
  exact: {
    ok: {
      title: 'Exact connection successful',
      body: 'Exact online is successfully connected.',
    },
    error: {
      title: 'Exact connection failed',
      body: {
        invalid_request: 'Sending request to exact failed.',
        invalid_client: 'Sending client to Exact failed.',
        invalid_grant: 'Sending grant to Exact failed.',
        invalid_scope: 'Sending cope to Exact failed. ',
        unauthorized_client: 'Sending onauthorised client to Exact.',
        unsupported_grant_type: 'Sending grant to Exact failed.',
      },
    },
  },
  unit4: {
    ok: {
      title: 'Unit 4 connection successful',
      body: 'Unit 4 is successfully connected.',
    },
    error: {
      title: 'Unit 4 connection failed',
      body: {
        invalid_request: 'Sending request to exact failed.',
        invalid_client: 'Sending client to Exact failed.',
        invalid_grant: 'Sending grant to Exact failed.',
        invalid_scope: 'Sending cope to Exact failed. ',
        unauthorized_client: 'Sending onauthorised client to Unit 4.',
        unsupported_grant_type: 'Sending grant to Unit 4 failed.',
      },
    },
  },
  warehouse: {
    stock: 'Stock',
    inbound: 'Inbound',
    outbound: 'Outbound',
    field: {
      code: { label: 'Code' },
      name: { label: 'Name' },
    },
  },
  step: {
    edit: {
      step: 'Step {{index}}',
      start: 'Start',
      end: 'End',
      addSectionButton: 'Add component',
    },
    field: {
      newBatchSerialNumberFormat: { label: 'New Serial Number Format' },
      label: { label: 'Process Step Name' },
      type: {
        value: {
          print: 'Print Step',
          form: 'Form Step',
          split: 'Batch Step',
          multiplier: 'Multiplier',
          grow: 'Grow Step',
          subprocesses: 'Subprocesses',
        },
        valueStep: {
          print: 'Print Step',
          form: 'Form Step',
          split: 'Batch Step',
          multiplier: 'Multiplier',
          grow: 'Grow Step',
          subprocesses: 'Subprocesses',
        },
      },
      sections: {
        label: 'Description',
        empty: 'This description is empty.',
      },
      workStation: { label: 'Work Station' },
      workMinutes: { label: 'Estimated Process Time' },
      capabilities: { label: 'Skills required' },
      workTempoManual: {
        label: 'Work Tempo (Manual)',
        unit: 'in plants / hour',
      },
      workTempoAutomatic: {
        label: 'Work Tempo (Automatic)',
        unit: 'in plants / hour',
      },
      operatorSupervision: {
        label: 'Operator Supervision Required',
      },
    },
  },
  printStep: {
    field: {
      type: {
        label: 'Print Type',
        value: {
          label: 'Label',
          document: 'Document',
        },
      },
      copies: { label: 'Default Number of Copies' },
      labelPrinter: {
        label: 'Printer',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      labelTemplate: {
        label: 'Template',
        keys: {
          article_type_name: 'Article name.',
          article_type_code: 'Article code.',
          article_type_gross_weight: 'Article gross weight.',
          warehouse_name: 'Warehouse name.',
          warehouse_code: 'Warehouse code.',
          warehouse_locations: 'Warehouse locations.',
          serial_number: 'Product serialnumber.',
          batch_size: 'Batch size',
          date: 'Date.',
          time: 'Time.',
          shipment_lines: 'Shipment lines table.',
          operator_badge_id: 'Operator Badge ID',
          operator_name: 'Operator Name',
          order_id: 'Order ID',
          erp_order_id: 'ERP Order ID',
          customer_name: 'Order Customer Relation Name',
          supplier_name: 'Order Supplier Relation Name',
          reference: 'Order Reference',
          remarks: 'Order Remarks',
          delivery_address: 'Order Delivery Address',
          delivery_date: 'Order Delivery Date',
          receipt_date: 'Order Receipt Date',
          quantity: 'Order Quantity',
          sales_order_line_description: 'Sales Order Line Description',
          purchase_order_line_description: 'Purchase Order Line Description',
          amount_ex_vat: 'Amount Excluding VAT',
          shipping_method_description: 'Shipping Method Description',
        },
      },
      documentBackground: { label: 'Background' },
      documentOverlays: {
        label: 'Template',
        template: 'Template',
        addPage: 'Page',
      },
      defaultPrinter: { label: 'Default Printer' },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Number of times' },
    },
  },
  formStep: {
    field: {
      form: { label: 'Form' },
      icon: { label: 'Icon' },
      optional: { label: ' (Optional)' },
    },
    perform: {
      between: 'Between {{min}} and {{max}}',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Form' },
      icon: { label: 'Icoon' },
      newBatchQuantity: {
        label: 'New Batch Size',
        info: 'One container contains one batch with X amount of plants. X is in this case the batch size.',
      },
      newBatchVariableQuantity: {
        label: 'Variable',
        info: 'Quantity is an expected quantity, the real quantity can vary.',
      },
      newBatchVariableQuantityPredetermined: {
        label: 'Variable Predetermined',
        info: 'The variable quantity is predetermined, this enables the quantity to already affect the step itself for certain form fields and how much you need to scan.',
      },
      newBatchVariableUseOrderSize: {
        label: 'Use order size?',
        info: 'The expected quantity is variable, this enables the quantity field to have a default value of the order size.',
      },
      printer: { label: 'Printer' },
      template: { label: 'Template' },
      copies: { label: 'Default Number of Copies' },
      type: {
        label: 'Identification by',
        value: {
          scan: 'Loading Carrier',
          print: 'Print / Label',
          article: 'Article Identification',
          provided: 'Provided Serial Number',
        },
        tooltip: {
          scan: "At identification by 'Loading Carrier' the batch is paired with the scanned loading carrier.",
          print: "At identification by 'Print/Label' a label is printed according the set template. If you do not add form fields to this batch step, you can labels with the printall feature.",
          article:
            "At identification by 'Article Identifaction' an article type should be scanned that is used in the process of identification.",
          provided: "At identification by 'Provided Serial Number' the serial number by which the batch should be identified is provided by the operator. This still has to match the new serial number format defined above.",
        },
      },
      defaultPrinter: { label: 'Default Printer' },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Label' },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          choice: 'Choice',
          article_type: 'Article Type',
          measure: 'Measurement',
          format: 'Format',
          variable: 'Variable',
          image: 'Image',
          quantity: 'Quantity',
          bom: 'BOM',
          storage_location: 'Storage Location'
        },
      },
      target: { label: 'Details' },
      articleType: { label: 'Article Type' },
      slug: {
        label: 'Print Reference',
        info:
          'This value can be used in other print templates. Only characters A-Z, a-z, 0-9 en _ are allowed and the value cannot start with a number',
      },
      storageLocations: {label: 'Storage location(s) (Quantity)'}
    },
  },
  batch: {
    batchInfoModal: {
      trigger: 'Batch Info',
    },
    overview: {
      title: 'Products',
      empty: 'No products are found.',
    },
    field: {
      batchType: { label: 'Type' },
      productionRequest: { label: 'Work ID' },
      inShipment: { label: 'Receive ID' },
      outShipment: { label: 'Pick ID' },
      warehouseTransfer: { label: 'Transfer ID' },
      purchaseOrderNumberERP: { label: 'Purchase Order ERP' },
      salesOrderNumberERP: { label: 'Sales Order ERP' },
      productionOrderNumberERP: { label: 'Production Order ERP' },
      orderNumberERP: { label: 'ERP Order' },
      batchUseds: {
        label: 'Used by',
        empty: 'This batch has not been used in any other batches.',
      },
      batchUsings: {
        label: 'Used',
        empty: 'This batch has not used any other batches.',
      },
      details: {
        label: 'Details',
        empty: 'This product has no details.',
      },
      performances: {
        label: 'Performances',
        empty: 'This product has no performances.',
        description: '{{step}} performed by {{operator}} @ {{createdAt}}',
        printedDocument: 'Printed document',
      },
      serialNumber: { label: 'Serial number' },
      finished: { label: 'Done' },
      scrapped: { label: 'Rejected' },
      loadCarrier: { label: 'Loading carrier' },
      quantityRemaining: { label: 'Quantity Remaining' },
      storageLocation: { label: 'Storage Location' },
    },
  },
  subassembly: {
    overview: {
      title: 'Sub products',
      empty: 'No sub products are found.',
    },
    field: {
      parentBatch: { label: 'Master product' },
      parentSubassembly: { label: 'Master sub product' },
      subassemblies: {
        label: 'Sub products',
        empty: 'This sub products has no other sub products.',
      },
      details: {
        label: 'Details',
        empty: 'This sub products has no details.',
      },
      performances: {
        label: 'Performances',
        empty: 'This sub product has no performances.',
        description: '{{step}} performed by {{operator}} @ {{createdAt}}',
      },
      serialNumber: { label: 'Serial number' },
      finished: { label: 'Done' },
    },
  },
  progress: {
    title: 'Progress overview',
    empty: 'There are no orders for this week.',
    processType: {
      label: 'Process Type',
      value: {
        make: 'Main Make',
        buy: 'Sub Inbound',
        sell: 'Sub Outbound',
        transfer_line: 'Sub Transfer line',
        stock_count: 'Main Stock Count',
        transfer: 'Main Transfer',
        pick_order: 'Main Pick Order',
        receive_order: 'Main Receive Order',
      },
    },
    productionLine: {
      stat: {
        todo: 'To do',
        inProgress: 'In progress',
        done: 'Done',
        operators: 'Operator',
        operators_plural: 'Operators',
      },
    },
    weekPicker: 'Week',
    monthPicker: 'Month',
    dayPicker: 'Day',
    processVersion: 'Version{{version}}',
    groupBy: {
      label: 'Group by',
      value: {
        workStation: 'Work Station',
        step: 'Step',
      },
    },
  },
  detail: {
    field: {
      value: { label: 'Value' },
      reason: { label: 'Reason' },
    },
  },
  targetInfoModal: {
    scrapped: 'Rejected: {{reason}}',
    variableQuantity: 'Variable Quantity',
    reprint: {
      title: 'Reprint',
      quantity: 'Number of Copies',
      printButton: 'Print',
    },
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Text',
        content: 'Content',
      },
      date: {
        label: 'Date',
        part: {
          label: 'Part',
          year: { label: 'Year' },
          month: {
            label: 'Month',
            text: 'Text',
            names: {
              january: {
                label: 'January',
                value: 'JAN',
              },
              february: {
                label: 'February',
                value: 'FEB',
              },
              march: {
                label: 'March',
                value: 'MAR',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'May',
                value: 'MAY',
              },
              june: {
                label: 'June',
                value: 'JUN',
              },
              july: {
                label: 'July',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'December',
                value: 'DEC',
              },
            },
          },
          day: { label: 'Day' },
          isoyear: { label: 'ISO Year' },
          isoweek: { label: 'ISO Week' },
          isoweekday: {
            label: 'ISO Weekday',
            text: 'Text',
            names: {
              monday: {
                label: 'Monday',
                value: 'Mo',
              },
              tuesday: {
                label: 'Tuesday',
                value: 'Tu',
              },
              wednesday: {
                label: 'Wednesday',
                value: 'We',
              },
              thursday: {
                label: 'Thursday',
                value: 'Th',
              },
              friday: {
                label: 'Friday',
                value: 'Fr',
              },
              saturday: {
                label: 'Saturday',
                value: 'Sa',
              },
              sunday: {
                label: 'Sunday',
                value: 'Su',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alphabet',
        digits: 'Number',
        expand: 'Expands',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Other, like:',
        },
      },
      anything: {
        label: 'Anything',
      },
    },
  },
  performanceResetModal: {
    title: 'Rework',
    reason: 'Reason',
    resetButton: 'Confirm rework',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'No text added',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'No title',
      },
    },
  },
  printerSetup: {
    connectionError: 'Error when pairing the printer.',
    connectionNameError: 'Unknown printer name.',
    connectionPdfError: 'Error when pairing the label.',
    title: 'Printer Setup',
    installQzTray: {
      beforeLink: 'Install ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Download ',
      link: 'this file',
      beforeLocation: ' and save it as ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Add the next line to the end of the file ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Printer test:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra/Toshiba',
      },
    },
    printerModal: {
      title: 'Test Print',
      selectPrinter: 'Select Printer',
      noPrintersAvailable: 'No Printers Available',
      printButton: 'Print',
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Mo' },
      tuesday: { label: 'Tu' },
      wednesday: { label: 'We' },
      thursday: { label: 'Th' },
      friday: { label: 'Fr' },
      saturday: { label: 'Sa' },
      sunday: { label: 'Su' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Week' },
        month: { label: 'Month' },
      },
    },
    slot: {
      planned: 'Planned',
      overtime: 'Overtime',
      absent: 'Absent',
    },
    leaveSlotModal: {
      title: {
        add: 'Add Leave Request',
        edit: 'Change Leave Request',
      },
    },
    workTimesModal: {
      title: 'Work Times {{scope}}',
    },
    total: {
      planned: '{{time}} planned',
      actual: '{{time}} worked',
      overtime: '{{time}} of overtime',
      absence: '{{time}} of absence',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Period' },
      fullDay: { label: 'Full Day' },
      name: { label: 'Reason' },
      type: {
        label: 'Type',
        value: {
          vacation: 'Vacation',
          rwh: 'RWH',
          special: 'Special',
        },
        valueShort: {
          vacation: 'VAC',
          rwh: 'RWH',
          special: 'SPE',
        },
        valueMinutes: {
          vacation: 'Vacation',
          rwh: 'RWH',
          special: 'Special Leave',
        },
      },
      status: {
        label: 'Status',
        value: {
          approved: 'Approved',
          pending: 'Pending',
          rejected: 'Rejected',
        },
      },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
    },
    overview: {
      title: 'Leave Requests',
      noneFound: 'No leave requests have been found.',
      noneSelected: 'No leave request has been selected yet.',
      hasComments: 'Has comments',
      availabilityHeader: 'Available Operator Hours',
      statusAction: {
        approved: 'Approve',
        pending: 'Reconsider',
        rejected: 'Reject',
      },
    },
    edit: {
      comments: {
        empty: 'No comments have been placed yet.',
      },
      stats: {
        allowed: 'Allowed',
        used: 'Used',
        available: 'Available',
        requested: 'Requested',
      },
      days: {
        legend: {
          full: 'Full Availability',
          approved: 'When Approved',
          rejected: 'When Rejected',
        },
      },
      legal: 'Legal',
      extra: 'Extra',
    },
  },
  leaveCalendar: {
    overview: {
      title: 'Leave Calendars',
      empty: 'No leave calendars could be found.',
      addButton: 'Leave calendar',
    },
    create: {
      title: 'Create Leave Calendar',
    },
    edit: {
      title: 'Edit Leave Calendar',
      saveBeforeLeaveSlot: 'You must first save this leave calendar before you can add leave.',
    },
    field: {
      name: { label: 'Name' },
      operators: { label: 'Operators' },
      users: { label: 'Users' },
      leaveSlots: { label: 'Leave' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Level',
        value: {
          1: 'Not qualified',
          2: 'Trainee',
          3: 'Qualified',
          4: 'Expert',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Processes',
      addButton: 'Process',
      empty: 'No processes could be found.',
    },
    edit: {
      newBatch: {
        label: 'New Batch',
        info: 'Performing this step will create a new batch, these fields specify some details about this batch.',
      },
      versioned: {
        label: '(versioned)',
        info: 'This value only applies to the currently selected version.',
      },
      baseFields: {
        label: 'Basic fields',
        info: 'These values do not pair with a specific version but to all versions.',
      },
      batchSize: {
        label: 'Batch Size',
        value: {
          productionRequest: 'Order Size',
          unit: 'Unit',
          batch: 'Batch of {{count}}',
        },
        error: {
          notSmaller: 'The batch size should be smaller then the previous batch size.',
          notFinal: 'Batch size must be equal to {{expected}}.',
        },
      },
      title: 'Edit Process',
      version: 'Version {{version}}',
      finalized: {
        label: 'Finalized at: {{date}}',
        by: 'Finalized by: {{user}}',
      },
      updated: {
        label: 'Updated at: {{date}}',
        by: 'Updated by: {{user}}',
      },
      noUpdateInfo: 'No information available when this version has been updated.',
      draft: 'Concept',
      finalizeButton: 'Finalize',
      nextVersionButton: 'Next Version',
      noStepSelected: 'No step selected',
      noBatchTypeSelected: 'No process selected',
      workStationsModal: {
        title: 'Select Work Stations',
      },
      tabs: {
        steps: 'Steps',
        instructions: 'Instructions',
        articleTypes: 'Article Types',
      },
      addArticleTypeModal: {
        title: 'Add Article Types',
        addButton: 'Add ({{count}})',
      },
      harvestDay: {
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
        sunday: 'Su',
      },
    },
    create: {
      title: 'Create Process',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Latest version' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Production Area' },
      batchType: { label: 'Process' },
      version: { label: 'Version' },
      newest: { label: 'Latest' },
      factory: { label: 'Location' },
      environmentScript: { label: 'Environment Script' },
      lightScript: { label: 'Light Script' },
      nutritionStorageScript: { label: 'Nutrition Storage Script' },
      nutritionIrrigationScript: { label: 'Nutrition Irrigation Script' },
    },
  },
  dataSource: {
    edit: {
      title: 'Edit Data Source',
    },
    create: {
      title: 'Create Data Source',
    },
    overview: {
      title: 'Data Sources',
      empty: 'No data sources are linked yet.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identification Code' },
      name: { label: 'Name' },
      deviceName: { label: 'Name of Source' },
      description: { label: 'Description' },
      workStations: { label: 'Paired Work Stations' },
      data: {
        label: 'Data',
        name: 'Name',
        type: 'Type',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Released',
          in_progress: 'In progress',
          finalized: 'Done',
          complete: 'Finalized',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Loading carriers',
      empty: 'No loading carriers that could be found.',
      addButton: 'Loading carrier',
      batchAddButton: 'Batch Add',
    },
    create: {
      title: 'Add Loading Carrier',
    },
    edit: {
      title: 'Edit Loading Carrier',
      weighing: '{{weight}}{{unit}}, weighed by {{user}} at {{time}}.',
      unknownUser: 'unknown',
    },
    batchAdd: {
      title: 'Batch Add Loading Carriers',
      quantity: 'Quantity',
      serialNumberFormat: 'Serial Number Format',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          pallet_euro: 'Euro pallet',
          pallet_pool: 'Pool pallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Box',
          stackbox: 'Stackable box',
        },
      },
      serialNumber: { label: 'Serial Number' },
      name: { label: 'Name' },
      description: { label: 'Description' },
      weight: { label: 'Weight' },
      width: { label: 'Width' },
      height: { label: 'Height' },
      length: { label: 'Length' },
      volume: { label: 'Volume' },
      picture: { label: 'Picture' },
      batch: { label: 'Batch' },
      hasBatch: { label: 'Has Batch' },
      weighings: { label: 'Weighings' },
      reweighDays: {
        label: 'Weighing Interval',
        info: 'Here you can specify how often the loading carrier needs to be weighed. In the loading carrier overview you can then see when the loading carrier needs to be weighed again and filter on loading carriers that needs to be weighed.',
        suffix: ' days',
      },
      reweighDate: { label: 'Reweigh Date' },
      needsReweighing: { label: 'Needs Reweighing' },
    },
  },
  login: {
    workstation: 'Workstation Code',
    barcode: 'Article Barcode',
    title: 'Tracy Picker',
    username: 'Username',
    batchcode: 'Batchcode',
    notAllowed: 'This is not a correct workstation code',
    password: 'Password',
    forgotPassword: 'Forgot your password?',
    clearusername: 'Clear username',
    clearworkstation: 'Erase workstation code',
    clearprinter: 'Erase printer pairing info',
    forgotMailSend: 'Send email to reset password',
    successful: 'Successfully logged in with badge ID',
    logIn: 'Log In',
    error: 'Error while logging in',
    wrong_credentials: 'Unrecognized batchcode',
  },
  shipping: {
    picture: 'Picture of the cargo',
    picture_accept: 'Accept picture',
    picture_decline: 'Re-take',
    packed: 'Finish packing article',
    start_send: 'Start shipping process',
    start_receive: 'Start receiving process',
    send_shipment: 'Send this shipment',
    receive_shipment: 'Accept receipt',
  },
  articleInfo: {
    clearBarcode: 'Erase barcode',
    search: 'Search for barcode',
    wrongCode: 'Barcode not available',
    notAllowed: 'This user is not allowed to have access to this info',
    error: 'Error while searching',
  },
  shipment: {
    articleInfo: {
      performanceLine:
        '{{stepLabel}} of process {{productionLineName}} performed @ {{performanceTime}} by {{performanceBy}}',
      batchType: 'Batch Type',
      batchDescription: 'Batch Description',
      batchSize: 'Batch Size',
      articleNameAndCode: 'Article Name & Code',
      scannedBarcode: 'Scanned Barcode',
    },
    picker: {
      default: 'Select a value',
    },
    shipmentInfo: 'Shipment Information',
    status: {
      picking: 'Picking',
      printing: 'Printing',
      sending: 'Sending',
      sent: 'Shipped',
      toReceive: 'To Receive',
      received: 'Received and Processed',
    },
    field: {
      salesOrder: {
        label: 'Sales Order',
      },
      purchaseOrder: { label: 'Purchase Order' },
      receiveDate: { label: 'Planned Receive Date' },
      receivedTime: { label: 'Receive Time' },
      shippingDate: {
        label: 'Shipping date',
      },
      freightType: {
        label: 'Typ of Cargo',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'Freight Company',
      },
      shippingName: {
        label: 'Driver',
      },
      shippingLicense: {
        label: 'License Plate',
      },
      shippingTime: {
        label: 'Time of Shipment',
      },
      quantity: {
        label: 'Quantity',
      },
      batchSize: {
        label: 'Batch Size',
      },
    },
    outbound: {
      pickitem: 'Pick Items',
      printreceipt: 'Print Receive note',
      package: 'Packing',
      printshipmentlabel: 'Print shipment label',
      send: 'Ship',
    },
    inbound: {
      receiveItem: 'Receive Order line',
      barcodeCreation: 'Identify products',
      warehousePlacement: 'Add to stock location',
    },
    heading: {
      cmrLines: 'CMR LINES',
    },
    titleInbound: 'Inbound shipment {{id}}',
    titleOutbound: 'Outbound shipment {{id}}',
    confirmShipment: 'Weet je zeker dat je {{custName}} een zending wilt sturen met trackcode {{trackingNumber}}?',
    confirmReceive:
      'Weet je zeker dat je van {{custName}} een zending wilt accepteren met trackcode {{trackingNumber}}?',
    sendShipmentTitle: 'Send',
    receiveShipmentTitle: 'Receive',
    scan: 'Scan',
    leftToPick: 'To Pick',
    actualyPicked: 'Picked',
    documents: {
      label: 'Documents',
      packingListRequired: 'Packing List',
      invoiceRequired: 'Invoice',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Articles',
      info: 'Info',
    },
    noCmrLines: 'This shipment does not have any CMR Lines.',
    box: 'Box {{id}}',
    buyBox: 'Box',
    hiddenBoxes: '{{count}} boxes',
    error: {
      wrongArticle: 'Wrong article.',
      alreadyScanned: 'Box already scanned.',
      alreadyShipped: 'Box already included in another pickorder.',
      tooManyProducts: 'Box contains too many products.',
      invalidQuantity: 'Invalid value for quantity.',
    },
  },
  common: {
    article: {
      field: {
        code: { label: 'Code' },
        name: { label: 'Name' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Quantity' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Sales Order' },
        purchaseOrder: { label: 'Purchase Order' },
        shippingDate: { label: 'Shipping Date' },
        receiveDate: { label: 'Planned Delivery Date' },
        shippingName: { label: 'Shipper Name' },
        shippingTime: { label: 'Shipped Time' },
        receivedTime: { label: 'Delivery Time' },
        shippingLicense: { label: 'Shipper License Plate' },
        freight: {
          label: 'Freight',
          value: {
            road: 'Road',
            air: 'Air',
            sea: 'Sea',
            parcel: 'Parcel',
          },
        },
        freightCompany: { label: 'Freight Company' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Mark' },
        number: { label: 'Number' },
        method: { label: 'Method' },
        nature: { label: 'Nature' },
        weight: { label: 'Weight' },
        cubage: { label: 'Cubage' },
      },
    },
    loading: 'Loading...',
    error: {
      somethingWentWrong: 'Something went wrong.',
    },
  },
  workTime: {
    field: {
      date: { label: 'Date' },
      startTime: { label: 'Start Time' },
      endTime: { label: 'End Time' },
      automaticallyClockedOut: { label: 'Clocked-out automatically' },
    },
  },
  softdeleted: {
    label: 'Show deleted items',
    value: {
      true: 'yes',
      false: 'no',
      only: 'only',
    },
  },
  logistics: {
    erpCounts: {
      header: {
        date: 'Date',
        quantity: '#',
      },
    },
  },
  extraLeaveBalance: {
    field: {
      year: { label: 'Applicable to (year)' },
      legalLeaveMinutes: { label: 'Legal' },
      extraLeaveMinutes: { label: 'Extra' },
      rwhMinutes: { label: 'RWH' },
    },
  },
  formField: {
    field: {
      slug: { label: 'Print slug' },
      articleType: {
        self: '(Dynamic)',
        selfInfo: "This will automatically fill in the Article Type where you're using this template.",
      },
      type: {
        quantityInfo: 'This type can be used to set a precise quantity on a variable batch. You can also enter a weight per unit, in this case the quantity will be based on a weight that the operator enters.',
      },
      measureMin: { label: 'Minimum' },
      measureMax: { label: 'Maximum' },
      quantityUnitWeight: { label: 'Weight per Unit' },
      articleTypeBatchSize: { label: 'Batch size?' },
      required: { label: 'Required' },
    },
  },
  integration: {
    overview: {
      title: 'Integrations',
      empty: 'No integrations found.',
      clearSyncErrorButton: 'Clear Sync Errors',
      addButton: 'Integration',
      inactive: 'This integration is not active.',
    },
    create: {
      title: 'Add Integration',
    },
    edit: {
      title: 'Edit Integration',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          exact: 'Exact',
          unit4: 'Unit 4',
          navision: 'Navision'
        },
      },
      name: { label: 'Name' },
      syncErrorCount: { label: 'Sync Errors' },
    },
    syncmodal: {
      title: 'Synchronize',
      syncButton: 'Sync',
      pending: 'Pending',
      from_db: 'Cached',
      from_inline: 'Partial',
      created: 'Created',
      updated: 'Updated',
      requested: 'Requested',
      dependencies: 'Dependencies',
      measureMin: { label: 'Min' },
      measureMax: { label: 'Max' },
      quantityUnitWeight: { label: 'Unit Weight' },
    },
    syncers: {
      exact_account_syncer: 'Accounts',
      exact_address_syncer: 'Addresses',
      exact_item_syncer: 'Items',
      exact_project_syncer: 'Projects',
      exact_purchase_order_syncer: 'Purchase orders',
      exact_sales_order_syncer: 'Sales Orders',
      exact_warehouse_syncer: 'Warehouses',
      exact_storage_location_syncer: 'Storage Locations',
      exact_item_warehouse_syncer: 'Stocks (per Warehouse)',
      exact_item_warehouse_location_syncer: 'Stocks (per Location)',
      exact_goods_receipt_syncer: 'Goods Receipt',
      exact_warehouse_transfer_syncer: 'Warehouse Transfers',
      exact_stock_position_syncer: 'Stock Updates',
      exact_shipping_method_syncer: 'Shipping Methods',
      exact_document_syncer: 'Documents',
    },
  },
  exactIntegration: {
    overview: {
      authButton: 'Activate Exact Integration',
      authResult: {
        ok: 'Exact integration activated successfully.',
        error: {
          invalid_request: 'Invalid request sent to Exact.',
          invalid_client: 'Invalid client sent to Exact.',
          invalid_grant: 'Invalid grant sent to Exact.',
          invalid_scope: 'Invalid scope sent to Exact. ',
          unauthorized_client: 'Unauthorized client sent to Exact.',
          unsupported_grant_type: 'Unsupported grant type sent to Exact.',
        },
      },
      syncButton: 'Sync with Exact',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact failed.' },
        success: {
          notification: 'Successfully synced with Exact',
          count: {
            address: {
              tracy: {
                created: 'Created {{count}} Address in Tracy.',
                created_plural: 'Created {{count}} Addresses in Tracy.',
                updated: 'Updated {{count}} Address in Tracy.',
                updated_plural: 'Updated {{count}} Addresses in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Address in Exact.',
                created_plural: 'Created {{count}} Addresses in Exact.',
                updated: 'Updated {{count}} Address in Exact.',
                updated_plural: 'Updated {{count}} Addresses in Exact.',
              },
            },
            item: {
              tracy: {
                created: 'Created {{count}} Item in Tracy.',
                created_plural: 'Created {{count}} Items in Tracy.',
                updated: 'Updated {{count}} Item in Tracy.',
                updated_plural: 'Updated {{count}} Items in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Item in Exact.',
                created_plural: 'Created {{count}} Items in Exact.',
                updated: 'Updated {{count}} Item in Exact.',
                updated_plural: 'Updated {{count}} Items in Exact.',
              },
            },
            project: {
              tracy: {
                created: 'Created {{count}} Project in Tracy.',
                created_plural: 'Created {{count}} Projects in Tracy.',
                updated: 'Updated {{count}} Project in Tracy.',
                updated_plural: 'Updated {{count}} Projects in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Project in Exact.',
                created_plural: 'Created {{count}} Projects in Exact.',
                updated: 'Updated {{count}} Project in Exact.',
                updated_plural: 'Updated {{count}} Projects in Exact.',
              },
            },
            storage_location_stock_position: {
              tracy: {
                created: 'Created {{count}} Storage Location Stock Position in Tracy.',
                created_plural: 'Created {{count}} Storage Location Stock Positions in Tracy.',
                updated: 'Updated {{count}} Storage Location Stock Position in Tracy.',
                updated_plural: 'Updated {{count}} Storage Location Stock Positions in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Storage Location Stock Position in Exact.',
                created_plural: 'Created {{count}} Storage Location Stock Positions in Exact.',
                updated: 'Updated {{count}} Storage Location Stock Position in Exact.',
                updated_plural: 'Updated {{count}} Storage Location Stock Positions in Exact.',
              },
            },
            bill_of_material: {
              tracy: {
                created: 'Created {{count}} Bill Of Material in Tracy.',
                created_plural: 'Created {{count}} Bill Of Materials in Tracy.',
                updated: 'Updated {{count}} Bill Of Material in Tracy.',
                updated_plural: 'Updated {{count}} Bill Of Materials in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Bill Of Material in Exact.',
                created_plural: 'Created {{count}} Bill Of Materials in Exact.',
                updated: 'Updated {{count}} Bill Of Material in Exact.',
                updated_plural: 'Updated {{count}} Bill Of Material in Exact.',
              },
            },
            bill_of_material_item: {
              tracy: {
                created: 'Created {{count}} Bill Of Material Item in Tracy.',
                created_plural: 'Created {{count}} Bill Of Material Items in Tracy.',
                updated: 'Updated {{count}} Bill Of Material Item in Tracy.',
                updated_plural: 'Updated {{count}} Bill Of Material Items in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Bill Of Material Item in Exact.',
                created_plural: 'Created {{count}} Bill Of Material Items in Exact.',
                updated: 'Updated {{count}} Bill Of Material Item in Exact.',
                updated_plural: 'Updated {{count}} Bill Of Material Items in Exact.',
              },
            },
            account: {
              tracy: {
                created: 'Created {{count}} Account in Tracy.',
                created_plural: 'Created {{count}} Accounts in Tracy.',
                updated: 'Updated {{count}} Account in Tracy.',
                updated_plural: 'Updated {{count}} Accounts in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Account in Exact.',
                created_plural: 'Created {{count}} Accounts in Exact.',
                updated: 'Updated {{count}} Account in Exact.',
                updated_plural: 'Updated {{count}} Accounts in Exact.',
              },
            },
            sales_order: {
              tracy: {
                created: 'Created {{count}} Sales Order in Tracy.',
                created_plural: 'Created {{count}} Sales Orders in Tracy.',
                updated: 'Updated {{count}} Sales Order in Tracy.',
                updated_plural: 'Updated {{count}} Sales Orders in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Sales Order in Exact.',
                created_plural: 'Created {{count}} Sales Orders in Exact.',
                updated: 'Updated {{count}} Sales Order in Exact.',
                updated_plural: 'Updated {{count}} Sales Orders in Exact.',
              },
            },
            purchase_order: {
              tracy: {
                created: 'Created {{count}} Purchase Order in Tracy.',
                created_plural: 'Created {{count}} Purchase Orders in Tracy.',
                updated: 'Updated {{count}} Purchase Order in Tracy.',
                updated_plural: 'Updated {{count}} Purchase Orders in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Purchase Order in Exact.',
                created_plural: 'Created {{count}} Purchase Orders in Exact.',
                updated: 'Updated {{count}} Purchase Order in Exact.',
                updated_plural: 'Updated {{count}} Purchase Orders in Exact.',
              },
            },
            shop_order: {
              tracy: {
                created: 'Created {{count}} Production Order in Tracy.',
                created_plural: 'Created {{count}} Production Orders in Tracy.',
                updated: 'Updated {{count}} Production Order in Tracy.',
                updated_plural: 'Updated {{count}} Production Orders in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Production Order in Exact.',
                created_plural: 'Created {{count}} Production Orders in Exact.',
                updated: 'Updated {{count}} Production Order in Exact.',
                updated_plural: 'Updated {{count}} Production Orders in Exact.',
              },
            },
            shop_order_material_plan: {
              tracy: {
                created: 'Created {{count}} Production Order Material Plan in Tracy.',
                created_plural: 'Created {{count}} Production Order Material Plans in Tracy.',
                updated: 'Updated {{count}} Production Order Material Plan in Tracy.',
                updated_plural: 'Updated {{count}} Production Order Material Plans in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Production Order Material Plan in Exact.',
                created_plural: 'Created {{count}} Production Order Material Plans in Exact.',
                updated: 'Updated {{count}} Production Order Material Plan in Exact.',
                updated_plural: 'Updated {{count}} Production Order Material Plans in Exact.',
              },
            },
            shop_order_receipt: {
              tracy: {
                created: 'Created {{count}} Production Order Receipt in Tracy.',
                created_plural: 'Created {{count}} Production Order Receipts in Tracy.',
                updated: 'Updated {{count}} Production Order Receipt in Tracy.',
                updated_plural: 'Updated {{count}} Production Order receipts in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Production Order Receipt in Exact.',
                created_plural: 'Created {{count}} Production Order Receipts in Exact.',
                updated: 'Updated {{count}} Production Order Receipt in Exact.',
                updated_plural: 'Updated {{count}} Production Order Receipts in Exact.',
              },
            },
            shop_order_reversal: {
              tracy: {
                created: 'Created {{count}} Production Order Reversal in Tracy.',
                created_plural: 'Created {{count}} Production Order Reversals in Tracy.',
                updated: 'Updated {{count}} Production Order Reversal in Tracy.',
                updated_plural: 'Updated {{count}} Production Order Reversals in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Production Order Reversal in Exact.',
                created_plural: 'Created {{count}} Production Order Reversals in Exact.',
                updated: 'Updated {{count}} Production Order Reversal in Exact.',
                updated_plural: 'Updated {{count}} Production Order Reversals in Exact.',
              },
            },
            opportunity: {
              tracy: {
                created: 'Created {{count}} Opportunity in Tracy.',
                created_plural: 'Created {{count}} Opportunities in Tracy.',
                updated: 'Updated {{count}} Opportunity in Tracy.',
                updated_plural: 'Updated {{count}} Opportunities in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Opportunity in Exact.',
                created_plural: 'Created {{count}} Opportunities in Exact.',
                updated: 'Updated {{count}} Opportunity in Exact.',
                updated_plural: 'Updated {{count}} Opportunities in Exact.',
              },
            },
            quotation: {
              tracy: {
                created: 'Created {{count}} Quotation in Tracy.',
                created_plural: 'Created {{count}} Quotations in Tracy.',
                updated: 'Updated {{count}} Quotation in Tracy.',
                updated_plural: 'Updated {{count}} Quotations in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Quotation in Exact.',
                created_plural: 'Created {{count}} Quotations in Exact.',
                updated: 'Updated {{count}} Quotation in Exact.',
                updated_plural: 'Updated {{count}} Quotations in Exact.',
              },
            },
            goods_receipt: {
              tracy: {
                created: 'Created {{count}} Goods Receipt in Tracy.',
                created_plural: 'Created {{count}} Goods Receipts in Tracy.',
                updated: 'Updated {{count}} Goods Receipt in Tracy.',
                updated_plural: 'Updated {{count}} Goods Receipts in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Goods Receipt in Exact.',
                created_plural: 'Created {{count}} Goods Receipts in Exact.',
                updated: 'Updated {{count}} Goods Receipt in Exact.',
                updated_plural: 'Updated {{count}} Goods Receipts in Exact.',
              },
            },
            goods_delivery: {
              tracy: {
                created: 'Created {{count}} Goods Delivery in Tracy.',
                created_plural: 'Created {{count}} Goods Deliveries in Tracy.',
                updated: 'Updated {{count}} Goods Delivery in Tracy.',
                updated_plural: 'Updated {{count}} Goods Deliveries in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Goods Delivery in Exact.',
                created_plural: 'Created {{count}} Goods Deliveries in Exact.',
                updated: 'Updated {{count}} Goods Delivery in Exact.',
                updated_plural: 'Updated {{count}} Goods Deliveries in Exact.',
              },
            },
            sales_return: {
              tracy: {
                created: 'Created {{count}} Sales Return in Tracy.',
                created_plural: 'Created {{count}} Sales Returns in Tracy.',
                updated: 'Updated {{count}} Sales Return in Tracy.',
                updated_plural: 'Updated {{count}} Sales Returns in Tracy.',
              },
              exact: {
                created: 'Created {{count}} sales return in Exact.',
                created_plural: 'Created {{count}} sales returns in Exact.',
                updated: 'Updated {{count}} sales return in Exact.',
                updated_plural: 'Updated {{count}} sales returns in Exact.',
              },
            },
            warehouse_transfer: {
              tracy: {
                created: 'Created {{count}} Warehouse Transfer in Tracy.',
                created_plural: 'Created {{count}} Warehouse Transfers in Tracy.',
                updated: 'Updated {{count}} Warehouse Transfer in Tracy.',
                updated_plural: 'Updated {{count}} Warehouse Transfers in Tracy.',
              },
              exact: {
                created: 'Created {{count}} Warehouse Transfer in Exact.',
                created_plural: 'Created {{count}} Warehouse Transfers in Exact.',
                updated: 'Updated {{count}} Warehouse Transfer in Exact.',
                updated_plural: 'Updated {{count}} Warehouse Transfers in Exact.',
              },
            },
            stock_count: {
              exact: {
                created: 'Created {{count}} Stock Count in Exact.',
                created_plural: 'Created {{count}} Stock Counts in Exact.',
              },
            },
            shipping_method: {
              tracy: {
                created: 'Created {{count}} shipping method in Tracy.',
                created_plural: 'Created {{count}} shipping methods in Tracy.',
                updated: 'Updated {{count}} shipping method in Tracy.',
                updated_plural: 'Updated {{count}} shipping methods in Tracy.',
              },
              exact: {
                created: 'Created {{count}} shipping method in Exact.',
                created_plural: 'Created {{count}} shipping methods in Exact.',
                updated: 'Updated {{count}} shipping method in Exact.',
                updated_plural: 'Updated {{count}} shipping methods in Exact.',
              },
            },
            document: {
              exact: {
                created: 'Created {{count}} document in Exact.',
                created_plural: 'Created {{count}} documents in Exact.',
                updated: 'Updated {{count}} document in Exact.',
                updated_plural: 'Updated {{count}} documents in Exact.',
              },
            },
          },
        },
      },
    },
    field: {
      division: { label: 'Division' },
      webhooks: { label: 'Webhooks' },
      address: { label: 'Address' },
      item: { label: 'Item' },
      manageEndpointsHeader: { label: 'Manage Exact API Endpoints' },
      manageWebhooksHeader: { label: 'Manage Exact Webhooks Topics' },
      account: { label: 'Account' },
      opportunity: { label: 'Opportunity' },
      salesOrder: { label: 'Sales Order' },
      purchaseOrder: { label: 'Purchase Order' },
      project: { label: 'Project' },
      billOfMaterial: { label: 'Bill Of Material' },
      billOfMaterialItem: { label: 'Bill Of Material Item' },
      shopOrder: { label: 'Shop Order' },
      shopOrderMaterialPlan: { label: 'Shop Order Material Plan' },
      shopOrderReceipt: { label: 'Shop Order Receipt' },
      shopOrderReversal: { label: 'Shop Order Reversal' },
      salesReturn: { label: 'Sales Return' },
      warehouse: { label: 'Warehouse' },
      storageLocationStockPosition: { label: 'Storage Location Stock Position' },
      itemWarehouse: { label: 'Item Warehouse' },
      warehouseTransfer: { label: 'Warehouse Transfer' },
      goodsReceipt: { label: 'Goods Receipt' },
      goodsDelivery: { label: 'Goods Delivery' },
      stockCount: { label: 'Stock Count' },
      stockCountReversal: { label: 'Stock Count Reversal' },
      stockTopic: { label: 'Stock' },
      stockSerialNumber: { label: 'Stock Serial Number' },
      shippingMethod: { label: 'Shipping Method' },
      salesOrderWebhookTopic: { label: 'Sales Order Webhook' },
      document: { label: 'Document' },
    },
    edit: {
      topics: {
        title: 'Manage Exact API Endpoints',
        subtitle: {
          webhooks: 'Webhooks',
          general: 'General',
          production: 'Production',
          warehouse: 'Warehouse',
        },
      },
    },
  },
  unit4Integration: {
    overview: {
      authButton: 'Activate Unit4 Integration',
      authResult: {
        ok: 'Unit4 integration activated successfully.',
        error: {
          invalid_request: 'Invalid request sent to Unit4.',
          invalid_client: 'Invalid client sent to Unit4.',
          invalid_grant: 'Invalid grant sent to Unit4.',
          invalid_scope: 'Invalid scope sent to Unit4. ',
          unauthorized_client: 'Unauthorized client sent to Unit4.',
          unsupported_grant_type: 'Unsupported grant type sent to Unit4.',
        },
      },
      syncButton: 'Sync with Unit4',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact failed.' },
        success: {
          notification: 'Successfully synced with Exact',
        },
      },
    },
    field: {
      administration: { label: 'Administration code' },
      baseUrl: { label: 'Unit 4 server base URL' },
      manageEndpointsHeader: { label: 'Manage Unit4 API-endpoints' },
      goodsReceipt: { label: 'Goods Receipt' },
      product: { label: 'Product' },
      productDetails: { label: 'Product Detail' },
      supplier: { label: 'Supplier' },
      purchaseOrder: { label: 'Purchase Order' },
      customer: { label: 'Customer' },
      order: { label: 'Order' },
    },
  },
  syncError: {
    overview: {
      title: 'Sync Errors',
      empty: 'No sync errors found.',
    },
    field: {
      id: { label: 'ID' },
      message: { label: 'Message' },
    },
  },
  project: {
    overview: {
      title: 'Projects',
      empty: 'No projects that could be found.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      description: { label: 'Description' },
      notes: { label: 'Notes' },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
    },
  },
  billOfMaterialVersion: {
    edit: {
      title: 'View Bill Of Material',
    },
    field: {
      version: {
        label: 'Version',
      },
      status: {
        label: 'Status',
      },
      description: {
        label: 'Description',
      },
      default: {
        label: 'Default',
      },
    },
  },
  billOfMaterialItem: {
    field: {
      articleType: { label: 'Article Type' },
      description: { label: 'Description' },
      quantity: { label: 'Quantity' },
      required: { label: '# Required' },
      assigned: { label: '# Allocated' },
      backflush: { label: 'Backflush' },
      number: { label: 'Line' },
      warehouse: { label: 'Warehouse' },
      storageLocations: { label: 'Storage Locations' }
    },
  },
  productionOrder: {
    field: {
      billOfMaterialVersion: { label: 'BOM Version' },
      warehouse: { label: 'Warehouse' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Released',
          finished: 'Finished',
        },
      },
    },
    billOfMaterialVersionPlaceholder: 'No BOM available',
    procesPlaceholder: 'No make process',
    edit: {
      title: 'Edit Production Order',
    },
    create: {
      title: 'Create Production Order',
    },
  },
  script: {
    minXInfo: 'Min X is not yet available for this location',
    release:{
      finalize: 'Finalize',
      revert: 'Revert',
      cannotRevert: 'Reverting is not possible, this chapter is in use',
    },
    library: 'Library',
    overview: {
      title: 'Script Library',
      empty: 'No chapters found.',
      addButton: 'Add Script',
      chapterTitle: 'Chapter Library',
      chapterAddButton: 'Add Chapter',
    },
    create: {
      title: 'Add Chapter',
    },
    edit: {
      title: 'Edit Chapter',
      schedule: 'Day Schedule',
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied successfully',
        pasted: 'Pasted successfully',
        pasteError: 'Nothing to paste',
        pasteTypeMismatch: 'Clipboard contains chapter of a different type',
      },
      fixOrdering: 'Fix Time Ordering',
      orderWillBeFixed: 'Order will automatically be fixed when saving',
    },
    view:{
      'title': 'View Script',
      'popup': 'View',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          environment: 'Climate',
          light: 'Light',
          fertilizer: 'Fertilizer',
          nutrition_storage: 'Nutrition storage',
          nutrition_irrigation: 'Irrigation',
          package: 'Scripts running',
        },
      },
      location: {
        label: 'Location',
        display: {
            multilayer: 'Multilayer',
            highwire: 'Highwire',
            rnd: 'R&D',
            both: 'Multilayer; Highwire',
        },
        value:{
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          rnd: 'R&D',
          both: 'Both',
        },
      },
      name: { label: 'Name' },
      repeat: { label: 'Duration in days' },
      status: {
        label: 'Status',
        info: 'Scripts with status \'production\' or \'archived\' cannot be edited.',
        value: {
          concept: 'Concept',
          test: 'Test',
          production: 'Production',
          archived: 'Archived',
        },
      },
      notes: { label: 'Notes (deprecated)' },
      sendComment: 'Send Comment (CTRL + ENTER)',
      climate: {
        name: 'Climate Recipe',
        placeholder: 'Select climate recipe',
      },
      fertilizer: {
        name: 'Fertilizer Recipe',
        placeholder: 'Select  fertilizer recipe',
      },
      createdAt: {
        label: "Created at",
      },
      createdBy: {
        label: 'by',
      },
      creator:{
        label: 'Created by',
      },
      runningIn: {
        label: 'Running in',
      },
      notRunning: {
        label: 'Not Running',
      },
      currentlyRunning: {
        label: 'Running in',
      },
      lastRunningAt: {
        label: 'Last used',
      },
      daylight: {
        label: 'Daylight',
        min: 'Min hours daytime',
        max: 'Max hours daytime',
      },
    },
    filter: {
      search: {
        label: 'Search',
        placeholder: 'Name, ID or note ...',
      },
      status: {
        label: 'Status',
        option: {
          concept: 'Draft',
          test: 'Test',
          production: 'Final',
          archived: 'Archived',
          draft: 'Draft',
          final: 'Final',
        }
      },
      location: {
        label: 'Plantation area',
        option: {
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          rnd: 'R&D'
        },
      },
      runningInChamber: {
        label: 'Running in chamber',
        placeholder: 'Start typing to search for a chamber',
      },
      usedInScript: {
        label: 'Used in script',
        placeholder: 'Start typing to search for a script',
      },
    },
    history: {
      action: {
        edit: "edited chapter",
        created: "created chapter",
        note: "left a note",
        applied: "applied chapter",
        stopped: "stopped chapter",
      },
    },
  },
  recipe: {
    overview: {
      title: 'Recipe library',
      empty: 'No recipes found',
      addButton: 'Add Recipe',
    },
    field: {
      name:{
        label: 'Search',
        text: 'Name',
        placeholder: 'Name, Id or note ...',
      },
      type:{
        label: 'Plantation Area',
      },
      plantationArea: {
        label: 'Plantation Area',
      },
      status:{
        value:{
          active: 'Active',
          archived: 'Archived',
          all: 'All',
        }
      },
      location:{
        value:{
          all: 'All',
        },
      },
      notes: {
        label: 'Notes',
        empty: 'No notes',
        more: ', (more)',
      },
    },
    edit: {
      duplicate: 'Duplicate',
      archive: 'Archive',
      unarchive: 'Unarchive',
      archived: 'ARCHIVED',
      warningmodal:{
        header: 'Can not archive recipe!',
        recipe: 'Chapter',
        content: 'can not be archived as it is currently running.',
      },
      modal:{
        header: 'Are you sure?',
        recipe: 'Chapter',
        content: 'will be archived.',
      },
    },
  },
  environmentScript: {
    field: {
      directionInterval: { label: 'Direction Interval' },
      speed: { label: 'Velocity' },
      tIn: { label: 'T in' },
      evaporation: { label: 'Evaporation' },
      co2In: { label: 'CO₂' },
      minX: {label: 'Minimum X'},
      startTime: { label: 'Time' },
      deltaX: { label: 'ΔX' },
      direction: {
        value: {
          0: 'Off',
          1: 'A to B',
          2: 'B to A',
        },
      },
    },
  },
  environmentScriptSchedule: {
    field: {
      directionInterval: { label: 'Direction Interval' },
      speed: { label: 'Velocity' },
      tIn: { label: 'T in' },
      evaporation: { label: 'Evaporation' },
      co2In: { label: 'CO₂' },
      minX: {label: 'Minimum X'},
      startTime: { label: 'Time' },
    },
  },
  nutritionStorageScript: {
    field: {
      fertilizerScript: { label: 'Fertilizer' },
      ec: { label: 'EC' },
      ph: { label: 'pH' },
    },
  },
  nutritionIrrigationScript: {
    field: {
      pulse: { label: 'Open Time' },
      cycleNumber: { label: 'Cycle Number' },
      startTime: { label: 'Time' },
    },
  },
  nutritionIrrigationScriptSchedule: {
    field: {
      pulse: { label: 'Open Time' },
      cycleNumber: { label: 'Cycle Number' },
      startTime: { label: 'Time' },
    },
  },
  fertilizerScript: {
    field: {
      salpeterzuurA: { label: 'Nitric Acid' },
      kalksalpeterA: { label: 'Calcium Nitrate' },
      reserveA: { label: 'Epsom Salts 1' },
      bitterzoutA: { label: 'Epsom Salts 2' },
      fosforzuurA: { label: 'Phosphoric Acid' },
      kaliloogA: { label: 'Potassium Hydroxide' },
      microElementsA: { label: 'Trace Elements' },
      ijzerA: { label: 'Ironchelate DTPa 6%' },
    },
  },
  lightScript: {
    field: {
      intensity: {
        label: 'Intensity',
        max: 'Max Intensity',
        min: 'Min Intensity',
      },
      startTime: { label: 'Time' },
    },
  },
  lightScriptSchedule: {
    field: {
      intensity: { label: 'Intensity' },
      startTime: { label: 'Time' },
    },
  },
  growStep: {
    field: {
      plantationLine: { label: 'Plantation Area' },
      days: { label: 'Days' },
      color: {
        label: 'Color',
        value: {
          blue: 'Blue',
          purple: 'Purple',
          red: 'Red',
          orange: 'Orange',
          green: 'Green',
        },
      },
    },
  },
  workStationSession: {
    overview: {
      title: 'Work Station Sessions',
      empty: 'No work station sessions found.',
      viewPrintJobsButton: 'View print jobs',
    },
    field: {
      id: { label: 'ID' },
      type: {
        label: 'Type',
        value: {
          desktop: 'Desktop',
          mobile: 'Mobile',
        },
      },
      printers: {
        label: 'Printers',
        empty: 'No printers available.',
      },
      workStation: { label: 'Work Station' },
      appVersion: { label: 'App Version' },
      isActive: { label: 'Is Active' },
      createdAt: { label: 'Started At' },
      updatedAt: { label: 'Last Active At' },
      printJobs: { label: 'Print Jobs' },
    },
  },
  printJob: {
    field: {
      printer: {
        label: 'Printer',
      },
      status: {
        label: 'Status',
        value: {
          todo: 'Todo',
          done: 'Done',
          failed: 'Failed',
        },
      },
      content: {
        label: 'Content',
      },
      copies: {
        label: 'Copies',
      },
      createdAt: {
        label: 'Created At',
      },
    }
  },
  operatorAssignment: {
    field: {
      workStation: { label: 'Work Station' },
      operator: { label: 'Operator' },
      startAt: { label: 'Start At' },
      endAt: { label: 'End At' },
    },
  },
  warehouseTransfer: {
    overview: {
      title: 'Warehouse Transfers',
      empty: 'No Warehouse Transfers.',
      viewLinesButton: 'View Warehouse Transfer Lines',
    },
    edit: {
      title: 'Edit Warehouse Transfer',
    },
    create: {
      title: 'Add Warehouse Transfer',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      entryDate: { label: 'Entry Date' },
      plannedDeliveryDate: { label: 'Planned Transfer Date' },
      completedOnDate: { label: 'Completed On Date' },
      status: {
        label: 'Status',
        value: {
          draft: 'Draft',
          processed: 'Processed',
        },
      },
      source: {
        label: 'Source',
        value: {
          manual_entry: 'Manual entry',
          import: 'Import',
          transfer_advice: 'Transfer advice',
          web_service: 'Web service',
        },
      },
      warehouseFrom: {
        label: 'Warehouse From',
      },
      warehouseTo: {
        label: 'Warehouse To',
      },
      type: {
        label: 'Transfer type',
        value: {
          true: 'Location',
          false: 'Warehouse',
        }
      },
      productionRequest: {
        label: 'Production request',
        isNull: 'Null work orders',
      },
      transferLines: {
        label: 'Warehouse Transfer Lines',
        value: 'Transfer Line {{id}}',
        empty: 'No Warehouse Transfer Lines were added.',
      },
    },
  },
  warehouseTransferLine: {
    empty: 'No Warehouse Transfer Lines.',
    create: {
      title: 'View Warehouse Transfer Lines',
    },
    field: {
      id: { label: 'ID' },
      lineNumber: { label: 'Line Number' },
      createdDate: { label: 'Created Date' },
      quantity: { label: 'Quantity' },
      articleType: { label: 'Article Type' },
      availableStock: { label: 'Available Stock' },
      noAvailableStock: { label: 'No stock availabe for the selected article type.' },
      storageLocation: {
        id: { label: 'ID' },
        warehouse: { label: 'Warehouse' },
        code: { label: 'Code' },
        stock: { label: 'Stock' },
      },
      storageLocationFrom: {
        label: 'From Storage Location',
      },
      storageLocationTo: {
        label: 'To Storage Location',
      },
      warehouseTransfer: {
        label: 'Transfer ID',
      },
    },
  },
  detailImageModal: {
    title: { label: 'Uploaded images' },
    popup: { label: 'View uploaded images' }
  },
  stockCount: {
    field: {
      warehouse: { label: 'Warehouse' },
      defaultStorageLocation: { label: 'Storage Location' },
    },
    edit: {
      title: 'Edit Stock Count'
    },
    title: { label: 'Uploaded images' },
    popup: { label: 'View uploaded images' },
    missingStockCountProcess: 'Create missing Stock Count process',
  },
  factory: {
    overview: {
      title: 'Locations',
      empty: 'No locations found.',
      addButton: 'Location',
    },
    create: {
      title: 'Add Location',
    },
    edit: {
      title: 'Edit Location',
    },
    field: {
      name: { label: 'Name' },
      timezone: { label: 'Location' },
      currentTime: { label: 'Current Time' },
    },
  },
  plantationAllocation: {
    field: {
      startDate: { label: 'Start' },
      endDate: { label: 'End' },
      rack: { label: 'Rack' },
      layer: { label: 'Layer' },
      position: { label: 'Position' },
    },
  },
  blueSkiesOverride: {
    articleType: {
      field: {
        code: { label: 'SKU' },
      },
    },
  },
  articleTypeStorageLocation: {
    field: {
      isDefault: { label: 'Default' },
      stock: { label: '# Available' },
      assigned: { label: '# Allocated' },
      warehouse: { label: 'Warehouse' },
    },
  },
  storageLocation: {
    field: {
      code: { label: 'Code' },
      name: { label: 'Name' },
    },
  },
  resourceAllocation: {
    field: {
      source: { label: 'Source' },
      productionOrder: { label: 'Production Order' },
      supplier: { label: 'Supplier' },
      reference: { label: 'Reference' },
      expectedDate: { label: 'Expected Date' },
      otherSalesOrders: { label: 'Other Sales Orders' },
      quantity: { label: 'Quantity' },
    },
  },
  navisionSalesOrder: {
    field: {
      orderDate: { label: 'Order Date' },
      requestedDeliveryDate: { label: 'Requested' },
      promisedDeliveryDate: { label: 'Promised' },
      lastDatetimeModified: { label: 'Last Modified' },
      deliveryAddress: { label: 'Address Line 1' },
      deliveryAddress2: { label: 'Address Line 2' },
      deliveryCity: { label: 'City' },
      deliveryPostcode: { label: 'Postal Code' },
      deliveryCountryRegionCode: { label: 'Region' },
      sellToAddress: { label: 'Address Line 1' },
      sellToAddress2: { label: 'Address Line 2' },
      sellToCity: { label: 'City' },
      sellToPostcode: { label: 'Postal Code' },
    },
  },
  businessRelation: {
    field: {
      name: { label: 'Customer Name' },
    },
  },
  unit4Customer: {
    field: {
      customerId: { label: 'Customer ID'}
    }
  },
  navisionCustomer: {
    field: {
      customerId: { label: 'Customer ID' },
    },
  },
  unit4Order : {
    field: {
      orderDate: { label: 'Delivery date'}
    }
  },
  salesOrderResponsibility: {
    field: {
      name: { label: 'Name' },
      company: { label: 'Company' },
      role: { label: 'Role' },
      email: { label: 'Email' },
      phoneNumber: { label: 'Phone Number' },
    },
  },
  exactAccount: {
    field: {
      number: { label: 'Exact Number' },
    },
  },
  exactSalesOrder: {
    field: {
      deliverAt: { label: 'Deliver At' },
      orderedAt: { label: 'Ordered At' },
      modified: { label: 'Modified At' },
      id: { label: 'ID' },
      number: { label: 'Number' },
    },
  },
  exactAddress: {
    field: {
      addressLine1: { label: 'Address Line 1' },
      addressLine2: { label: 'Address Line 2' },
      addressLine3: { label: 'Address Line 3' },
      city: { label: 'City' },
      postcode: { label: 'Postal Code' },
      countryName: { label: 'Country' },
    },
  },
  printModal: {
    title: 'Print {{item}}',
    selectPrinter: 'Select Printer',
    noPrintersAvailable: 'No Printers Available',
    printButton: 'Print',
    copies: 'Number of copies',
  },
  metafield: {
    overview: {
      title: 'Metafields',
      empty: 'No metafields found.',
      addButton: 'Metafield',
    },
    create: {
      title: 'Add Metafield',
    },
    edit: {
      title: 'Edit Metafield',
    },
    field: {
      name: { label: 'Name' },
      entryLevel: {
        label: 'Entry Level',
        value: {
          article_type: 'Article Type',
          load_carrier: 'Load Carrier',
        },
      },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          image: 'Image',
          choice: 'Choice',
          measure: 'Measure',
          format: 'Format',
        },
      },
      textLong: { label: 'Long' },
      choiceOptions: { label: 'Options' },
      choiceMultiple: { label: 'Multiple' },
      measureMin: { label: 'Minimum Value' },
      measureMax: { label: 'Maximum Value' },
      formatFormat: { label: 'Format' },
      default: { label: 'Default' },
    }
  },
  scriptAllocation: {
    field: {
      startAt: { label: 'Start At' },
      endAt: { label: 'End At' },
      script: { label: 'Script' },
      locations: { label: 'Locations' },
      setpoints: { label: 'Setpoints' },
    },
  },
  movement: {
    overview: {
      title: 'Movements',
      buffer: 'Buffer',
      unset: 'Unset',
      fromMachine: 'From Machine',
      toMachine: 'To Machine',
      bulkTimeButton: 'Set Time ({{count}})',
    },
    edit: {
      title: 'Edit Movement',
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          planned: 'Planned',
          ready: 'Ready',
          moving: 'Moving',
          moved: 'Moved',
        },
      },
      loadCarrier: { label: 'Loading Carrier' },
      date: { label: 'Date' },
      time: { label: 'Time' },
      readyAt: { label: 'Ready At' },
      movingAt: { label: 'Moving At' },
      movedAt: { label: 'Moved At' },
      from: { label: 'From' },
      to: { label: 'To' },
    },
  },
  topic: {
    field: {
      name: { label: 'Name' },
      responsible: { label: 'Responsible' },
      status: {
        label: 'Status',
        value: {
          red: 'Red',
          orange: 'Orange',
          green: 'Green',
          purple: 'Purple',
          grey: 'Grey',
        },
      },
      locations: {
        label: 'Locations',
        placeholder: 'Filter on locations ...',
      },
      processStep:{
        label: 'Process Step',
        placeholder: 'Filter on process step',
      },
    },
    edit: {
      title: 'Edit Process Step',
    },
    create: {
      title: 'Add Process Step',
    },
  },
  tracyPocket: {
    runCommand: 'Run',
    message: {
      success: 'Successfully sent',
      error: 'Error while sending message',
    },
    parameter: {
      lock: 'Lock parameters',
      unlock: 'Unlock parameters',
    },
    status: 'Status',
  },
  issue: {
    field: {
      name: { label: 'Title' },
      responsible: {
        label: 'Responsible',
        placeholder: 'Filter on responsible ...',
        empty: 'No responsible party',
      },
      status: {
        label: 'Status',
        value: {
          red: 'Red',
          orange: 'Orange',
          green: 'Green',
          purple: 'Purple',
          grey: 'Grey',
        },
      },
      description: { label: 'Description' },
      process: {
        label: 'Process',
        placeholder: 'Filter on process ...',
      },
      createdAt: { label: 'Created at' },
      resolved: { label: 'Resolved '},
      resolvedAt: { label: 'Resolved at ' },
      blocking: {
        label: 'Block locations',
      },
    },
    edit: {
      title: 'Edit Issue',
      newComment: 'New Comment',
      sendComment: 'Send Comment (CTRL + ENTER)',
      button: {
        resolve: 'Resolve issue',
      },
    },
    create: {
      title: 'Add Issue',
    },
    overview: {
      addButton: 'Add Issue',
      addTopicButton: 'Add Process Step',
      confirmDelete: 'Are you sure you want to delete {{issue}}?',
      confirmResolveModalTitle: 'Resolve issue?',
      confirmResolve: 'Are you sure you want to resolve {{issue}}? This action is final and cannot be undone.',
      layer: 'LAYER {{layer}}',
      stakeholderFilter: {
        label: 'Supplier',
        placeholder: 'All Suppliers',
      },
    },
    filters: {
      resolved: {
        label: 'Show resolved issues',
        value: {
          show: 'Show',
          hide: 'Hide',
        },
      },
    },
  },
  lms: {
    overview: {
      sidebarFilter: {
        label: 'Filter',
        placeholder: 'Type to search or filter ...'
      }
    },
    sensors: {
      overview: {
        title: 'Sensors',
        empty: 'No sensors found',
        addToGroupButton: 'Add to group',
        removeFromGroupButton: 'Remove from group',
        createScenarioButton: 'Create scenario',
        createSensorGroupButton: 'Create sensor group',
        fetchSensorsButton: 'Fetch sensors',
        sensorGroupAddModal: {
          title: 'Add to group',
          target: {
            label: 'Group',
          },
          cancelLabel: 'Cancel',
          proceedLabel: 'Add to group',
        },
        sensorGroupRemoveModal: {
          title: 'Remove from group',
          target: {
            label: 'Group(s) to remove sensor from'
          },
          cancelLabel: 'Cancel',
          proceedLabel: 'Remove from group'
        },
        sensorGroupEditModal: {
          title: 'Edit sensor group',
          create: {
            title: 'Create sensor group',
          },
          name: {
            label: 'Name',
          },
          color: {
            label: 'Color',
            value: {
              grey: 'Grey',
              red: 'Red',
              orange: 'Orange',
              green: 'Green',
              blue: 'Blue',
              purple: 'Purple',
            }
          }
        }
      }
    },
    tracyPocket: {
      runCommand: 'Execute',
      message: {
        success: 'Successfully sent',
        error: 'Error sending message',
      },
    },
    alarms: {
      overview: {
        title: 'Current alarms',
        empty: 'No alarms found',
      },
      field: {
        id: {
          label: 'ID',
        },
        scenario: {
          label: 'Scenario',
        },
        status: {
          label: 'Status',
          value: {
            resolved: 'Resolved',
            unresolved: 'Unresolved',
          },
        },
        claimedBy: {
          label: 'Claimed by',
          unclaimed: 'Unclaimed',
        },
        claimedAt: {
          label: 'Claimed at',
        },
        triggeredAt: {
          label: 'Triggered at',
          timeAgo: 'ago',
        }
      },
    },
    contentTemplate: {
      overview: {
        title: 'Content Templates',
        empty: 'No content templates found',
        addButton: 'Add content template',
        filter: {
          name: {
            label: 'Name',
            placeholder: 'Search on name',
          },
          medium: {
            label: 'Medium',
            placeholder: 'Search on medium',
          },
        }
      },
      field: {
        name: {
          label: 'Name',
        },
        medium: {
          label: 'Medium',
          value: {
            email: 'Email',
            sms: 'SMS',
            voice: 'Voice call',
            whatsapp: 'WhatsApp',
            telegram: 'Telegram',
          }
        },
        content: {
          label: 'Content',
          placeholder: 'Select content template',
        },
      },
      edit: {
        title: 'Edit content template',
      },
      create: {
        title: 'Add content template',
      }
    }
  },
  sensor: {
    field: {
      id: {
        label: 'ID',
        placeholder: 'Search on ID',
      },
      source: {
        label: 'Source',
      },
      rk: {
        label: 'RK',
        placeholder: 'Search on RK',
      },
      pid: {
        label: 'Process ID',
        placeholder: 'Search on process ID',
      },
      pname: {
        label: 'Process name',
        placeholder: 'Search on process name',
      },
      rawSensorName: {
        label: 'Raw sensor name',
        placeholder: 'Search on sensor name',
      },
      translatedName: {
        label: 'Translated name',
        placeholder: 'Search on translated name',
      },
      relatedSensor: {
        label: 'Related sensor',
        placeholder: 'Search...',
      },
      thresholdType: {
        label: 'Threshold type',
        value: {
          relative: 'Percentage',
          absolute: 'Absolute',
        },
      },
      mostRecentValue: {
        label: 'Most recent value',
      },
      minorMinMax: {
        label: 'Minor min/max',
        filterLabel: 'Minor min/max set?',
      },
      majorMinMax: {
        label: 'Major min/max',
        filterLabel: 'Major min/max set?',
      },
      delay: {
        label: 'Sample time',
      },
      groups: {
        label: 'Groups',
      },
      relatedTopic: {
        label: 'Related Topic',
        placeholder: 'Select...',
      },
      usage: {
        label: 'Status',
        usedIn: 'Used in {{count}} scenarios',
        triggeredIn: 'Triggered in {{count}} scenarios',
      }
    },
  },
  scenario: {
    overview: {
      title: 'Scenarios',
      addButton: 'Scenario',
      noScenarioSelected: 'No scenario selected',
      unsavedChanges: 'Your scenario has unsaved changes.',
      sidebar: {
        title: 'Scenarios',
        search: {
          label: 'Search',
          placeholder: 'Search for a scenario...'
        },
        cannotDeleteActiveScenario: 'Cannot delete active scenario',
      },
      toolbar: {
        settingsButton: 'Settings',
      },
      settingsModal: {
        header: 'Edit Scenario Settings',
        sidebar: {
          header: 'Settings',
          general: 'General',
          rateLimiting: 'Rate limiting',
          claims: 'Claims',
        },
      },
      updateTriggered: 'You cannot update action and condition of scenario that is currently triggered'
    },
    field: {
      name: { label: 'Name' },
      status: {
        label: 'Status',
        value: {
          draft: 'Draft',
          ready: 'Ready',
          active: 'Active',
        },
      },
      claimExpiration: {
        enabled: {
          label: 'Enable claim expiration',
          description: 'Enable or disable claim expiration for this scenario.',
        },
        time: {
          label: 'Claim expiration time',
          description: 'The time until an alarm will be automatically unclaimed.',
          placeholder: 'Enter a number',
          unit: 'minutes',
        },
      },
      basicRateLimiting: {
        label: 'Basic rate limiting',
        description: 'If a scenario is triggered {{x}} times in {{y}} minutes, it should not be able to send notifications for the next {{z}} minutes.',
        descriptionDisabled: 'If a scenario is triggered too often in a short period of time, it should not be able to send notifications for a while.',
        fields: {
          enabled: {
            label: 'Enable basic rate limiting',
            description: 'Enable or disable basic rate limiting for this scenario.',
          },
          maxTriggers: {
            label: 'Max triggers',
            description: 'The maximum amount of times a scenario can be triggered in the given time frame.',
            placeholder: 'Enter a number',
          },
          timeframe: {
            label: 'Time frame',
            description: 'The time frame in which the scenario can be triggered the maximum amount of times.',
            placeholder: 'Enter a number',
            unit: 'minutes',
          },
          duration: {
            label: 'Duration',
            description: 'The time the scenario will be blocked from sending notifications after the maximum amount of triggers has been reached.',
            placeholder: 'Enter a number',
            unit: 'minutes',
          },
        },
      },
      advancedRateLimiting: {
        label: 'Advanced rate limiting (bundling)',
        description: 'If there is a large amount of notifications in a small timeframe, bundle the notifications into one notification.',
        fields: {
          enabled: {
            label: 'Enable advanced rate limiting',
            description: 'Enable or disable advanced rate limiting for this scenario.',
          },
        },
      }
    },
    node: {
      add: {
        errors: {
          'data is not a dict': 'This node cannot be empty',
        },
      },
      reger: {
        label: 'Reger emails',
        add: 'Reger emails',
      },
      sensor: {
        label: '{{name}} — {{threshold}}',
        noneSelected: 'No sensor selected',
        add: 'Sensor',
        field: {
          multiplicity: {
            label: 'Multiplicity',
            value: {
              sensor: 'Sensor',
              sensorGroup: 'Group',
            },
          },
          sensor: {
            label: 'Sensor',
            placeholder: 'Type to search for a sensor',
          },
          threshold: {
            label: 'Threshold',
            value: {
              minor: 'Minor',
              major: 'Major',
            },
          },
        },
        errors: {
          'data is not a sensor': 'No sensor selected',
          'action immutable for triggered scenario': 'Cannot update action when scenario is triggered',
          'condition immutable for triggered scenario': 'Cannot update condition when scenario is triggered',
        }
      },
      sensorGroup: {
        label: '{{name}} — {{threshold}} — {{relation}}',
        noneSelected: 'No sensor group selected',
        add: 'Sensor Group',
        field: {
          multiplicity: {
            label: 'Multiplicity',
            value: {
              sensor: 'Sensor',
              sensorGroup: 'Group',
            }
          },
          sensorGroup: {
            label: 'Sensor group',
            placeholder: 'Type to search for a sensor group',
          },
          threshold: {
            label: 'Threshold',
            value: {
              minor: 'Minor',
              major: 'Major',
            },
          },
          relation: {
            label: 'Sensor relation',
            value: {
              and: 'AND',
              or: 'OR',
            },
            explanation: {
              and: 'all',
              or: 'one or more of the'
            }
          },
          explanation: {
            label: 'Explanation',
            content: 'If {{relation}} of the sensors in the group exceed their {{threshold}} threshold, this node will be triggered.'
          },
        },
        errors: {
          'data is not a sensor group': 'No sensor group selected',
        }
      },
      not: {
        label: 'Not',
        add: 'Not',
      },
      and: {
        label: 'And',
        add: 'And',
        field: {
          type: { label: 'Type' },
          explanation: {
            label: 'Explanation',
            content: 'This is an AND node. All child nodes must be true for this node to be true.'
          }
        },
        errors: {
          'data is not greater than or equal to 2': 'An AND node must have at least two child nodes.',
        }
      },
      or: {
        label: 'Or',
        add: 'Or',
        field: {
          type: { label: 'Type' },
          explanation: {
            label: 'Explanation',
            content: 'This is an OR node. At least one child node must be true for this node to be true.'
          }
        },
        errors: {
          'data is not greater than or equal to 2': 'An OR node must have at least two child nodes.',
        }
      },
      split: {
        label: 'Split',
        add: 'Split',
        errors: {
          'data is not greater than or equal to 2': 'A SPLIT node must have at least two child nodes.',
        }
      },
      time: {
        label: 'Time',
        add: 'Time',
        field: {
          slots: {
            label: 'Slots',
            add: 'Add timeslot',
          },
        },
        day: {
          monday: 'Mo',
          tuesday: 'Tu',
          wednesday: 'We',
          thursday: 'Th',
          friday: 'Fr',
          saturday: 'Sa',
          sunday: 'Su',
        },
      },
      issue: {
        label: 'Issue',
        add: 'Issue',
        field: {
          priority: { label: 'Priority' },
        },
      },
      notification: {
        label: 'Notification',
        add: 'Notification',
        noMediaSelected: 'No medium selected',
        noContentSelected: 'No content selected',
        field: {
          media: {
            label: 'Medium',
            value: {
              email: 'Email',
              sms: 'SMS',
              voice: 'Voice call',
              whatsapp: 'WhatsApp',
              telegram: 'Telegram',
            },
          },
          content: {
            label: 'Content Template',
            preview: 'Preview',
          },
          recipients: { label: 'Recipients' },
          executeInterval: {
            label: "Resend",
            description: "Resend notification every",
            minutes: "minutes",
          }
        },
        recipient: {
          type: {
            user: {
              label: 'User',
              placeholder: 'Type to search for a user',
            },
            group: {
              label: 'Group',
              placeholder: 'Type to search for a group',
            },
          },
          placeholder: 'Add recipient',
          noneSelected: 'No recipient',
        },
        errors: {
          'data is not a content': 'No notification content template selected',
          'data is not a user': 'No notification recipient selected',
          'data is not a group': 'No notification recipient selected',
          'key is not allowed': 'Recipient type error (key not allowed)',
          'key is missing': 'Recipient type error (key missing)',
        },
      },
      wait: {
        label: 'Wait {{duration}}',
        add: 'Wait',
        field: {
          duration: { label: 'Duration' },
        },
        errors: {
          'data is not greater than 0': 'Wait duration must be greater than 0',
        }
      },
    },
    tooltip: {
      undo: 'Undo {{text}}',
      redo: 'Redo {{text}}',
      cut: 'Cut',
      copy: 'Copy',
      paste: 'Paste',
      delete: 'Delete',
      deselect: 'Deselect',
    },
    action: {
      add: {
        sensor: 'add sensor',
        sensorGroup: 'add sensor group',
        and: 'add and',
        or: 'add or',
        not: 'add not',
        split: 'add split',
        time: 'add time',
        issue: 'add issue',
        notification: 'add notification',
        wait: 'add wait',
      },
      move: {
        sensor: 'move sensor',
        sensorGroup: 'move sensor group',
        and: 'move and',
        or: 'move or',
        not: 'move not',
        split: 'move split',
        time: 'move time',
        issue: 'move issue',
        notification: 'move notification',
        wait: 'move wait',
      },
      delete: {
        sensor: 'delete sensor',
        sensorGroup: 'delete sensor group',
        and: 'delete and',
        or: 'delete or',
        not: 'delete not',
        split: 'delete split',
        time: 'delete time',
        issue: 'delete issue',
        notification: 'delete notification',
        wait: 'delete wait',
      },
      changeRelation: {
        and: 'change to and',
        or: 'change to or',
      },
      cut: {
        sensor: 'cut sensor',
        sensorGroup: 'cut sensor group',
        and: 'cut and',
        or: 'cut or',
        not: 'cut not',
        split: 'cut split',
        time: 'cut time',
        issue: 'cut issue',
        notification: 'cut notification',
        wait: 'cut wait',
      },
      paste: {
        sensor: 'paste sensor',
        sensorGroup: 'paste sensor group',
        and: 'paste and',
        or: 'paste or',
        not: 'paste not',
        split: 'paste split',
        time: 'paste time',
        issue: 'paste issue',
        notification: 'paste notification',
        wait: 'paste wait',
      },
      changeSensorSensor: 'change sensor to {{name}}',
      changeSensorThreshold: {
        minor: 'change sensor threshold to minor',
        major: 'change sensor threshold to major',
      },
      changeSensorGroupSensorGroup: 'change sensor group to {{name}}',
      changeIssuePriority: {
        red: 'change issue priority to red',
        orange: 'change issue priority to orange',
        green: 'change issue priority to green',
        purple: 'change issue priority to purple',
        grey: 'change issue priority to grey',
      },
      changeNotificationMedium: {
        email: 'change notification media to email',
        sms: 'change notification media to SMS',
        voice: 'change notification media to voice call',
        whatsapp: 'change notification media to WhatsApp',
        telegram: 'change notification media to Telegram',
      },
      changeNotificationTemplate: 'change notification template to {{template}}',
      addNotificationRecipient: 'add notification recipient',
      changeNotificationRecipient: 'change notification recipient to {{recipient}}',
      changeNotificationRecipientType: {
        user: 'change notification recipient type to user',
        group: 'change notification recipient type to group',
      },
      deleteNotificationRecipient: 'delete notification recipient',
      addTimeSlot: 'add time slot',
      deleteTimeSlot: 'delete time slot',
      addTimeSlotLine: 'add time slot line',
      deleteTimeSlotLine: 'delete time slot line',
      toggleTimeSlotDay: 'toggle time slot day',
      changeTimeSlotStart: 'change time slot start',
      changeTimeSlotEnd: 'change time slot end',
      changeWaitDuration: 'change wait duration to {{duration}}',
    },
  },
  viewports: {
    scm: {
      overview: {
        title: 'Supply chain manager',
        tabs: {
          dailyHarvestTarget: 'Daily harvest target',
          map: 'Container view',
          issues: 'Technical issues',
          movements: 'Planned movements',
          containers: 'Containers',
        },
        issues: {
          field: {
            id: { label: 'Issue ID' },
            status: { label: 'Prio' },
            process: { label: 'Process' },
            issue: { label: 'Issue' },
          },
          view: 'View issue on map',
          edit: 'Edit',
        },
        blocks: {
          field: {
            row: { label: 'Row' },
            startDate: { label: 'From' },
            endDate: { label: 'To' },
          },
          view: 'View block on map',
          edit: 'Edit',
        },
      },
    },
  },
  graphs:{
    overview:{
      issues: {
        label: '{{issueCount}} Issues'
      },
      nutrition:{
        label: "Nutrition"
      },
      water:{
        label: "Water"
      },
      light:{
        label: "Light"
      },
      enviroment:{
        label: "Environment"
      },
      masterGraph:{
        label: "Master Graph"
      },
      types: {
        nutrition:{
          label: 'Nutrition',
          subType: {
            nutrition: {
              label: 'Nutrition'
            }
        }

        },
        multilayer:{
          label: 'Multilayer',
          subType: {
            water: {
              label: 'Water'
            },
            enviroment: {
              label: 'Environment'
            },
            research: {
              label: 'General'
            },
            lights: {
              label: "Light {{lamp}}"
            },
        }
        },
        highwire:{
          label: 'Highwire',
          subType: {
            water: {
              label: 'Water'
            },
            research: {
              label: 'General'
            },
            enviroment: {
              label: 'Environment'
            },
        },

        },
        rnd:{
          label: 'R&D',
          subType: {
          research: {
            label: "Research"
          },
          nutrition: {
            label: "Nutrition"
          },
          environment: {
            label: "Environment"
          }
        }

        },
        lights:{
          label: 'Lights',
          subType: {
          lamps: {
            label: "Light {{lamp}}"
          }
        }

        },
      }
    }
  },
  library:{
    nav: {
      scripts: {
        header: "Scripts",
        rnd: "R&D scripts",
        tryout: "Tryout scripts",
        production: "Production scripts",
        approved: 'Approved by Tryout',
        disapproved: 'Disapproved by Tryout',
      },
      recipes: {
        header: "Recipes",
        climate: "Climate recipes",
        fertilzer: "Fertilizer recipes",
      },
      chapter:{
        header: "Chapters",
        overview: "Overview",
        climate: 'Climate',
        fertilizer: 'Fertilizer',
        irrigation: 'Irrigation',
        light: 'Light',
        nutritionstorage: 'Nutrition Storage',
      }
    }
  },
  scriptPackage:{
    edit:{
      title:'Edit Script',
      phase: 'Phase',
    },
    mergeTool:{
      filterName:'Chapter name / ID',
      filterPlaceholder:'Provide input ...',
      scriptList:{
        title: 'Select 1 chapter of each type',
        running: 'Running on',
        used: 'Used in',
        locations: 'locations',
      },
    },
    release: {
      canRelease: 'Release',
      cannotRelease: 'Script can not be released as not all information is filled in',
      approve: 'Approve',
      disapprove: 'Reject',
      rejectModalHeader: 'Reject',
      rejectModalContent: 'Add a reason for the rejection to the notes',
      approveModalHeader: 'Approve',
      approveModalContent: 'Are you sure you want to approve this script?',
      archiveModalContent: "You're about to archive this script, are you sure?",
      deleteModalContent: "You're about to permanently delete this script. This action can not be undone. Are you sure?",
      missingApprovePermission: "You are missing the permission to approve this script",
      missingDisapprovePermission: "You are missing the permission to disapprove this script",
      missingCancelPermission: "You are missing the permission to revoke this approval",

    },
    overview: {
      empty: "No Scripts Found",
      title: "Scripts",
      release: "Release",
      unrelease: "Unrelease",
      releaseTo: "Release to",
      unreleaseTo: "Unrelease to",
      rnd: 'R&D',
      disapprovedByTryout: 'Disapproved by Tryout',
      tryout: 'Tryout',
      approvedByTryout: 'Approved by Tryout',
      production: 'Production',
      cancelApproval: 'Cancel approval',
      cancel: 'Cancel',
      cancelDisapproval: 'Cancel disapproval',
    },
    field:{
      name:{
        label:'R&D Name',
      },
      tryoutDescription:{
        label:'Tryout Description',
      },
      suffix:{
        label:'Script Suffix',
      },
      status:{
        label:'Status',
      },
      plantationArea:{
        label:'Plantation Area',
      },
      crop:{
        label:'Crop',
      },
      duration:{
        label:'Duration',
      },
      fixedDuration:{
        label:'Fixed Duration',
      },
      notes:{
        label:'Notes',
      },
    },
    filter: {
      search: {
        label: 'Script',
        placeholder: 'Start typing to search for a script',
      },
      plantationArea: {
        label: 'Plantation area',
        option: {
          multilayer: 'Multilayer',
          highwire: 'Highwire',
          rnd: 'R&D'
        }
      },
      climate: {
        label: 'Climate recipe',
        placeholder: 'Select climate chapter',
      },
      fertilizer: {
        label: 'Fertiliser recipe',
        placeholder: 'Select fertiliser chapter',
      },
      status: {
        label: 'Status',
        option: {
          active: 'Active',
          archived: 'Archived',
          all: 'All',
        }
      },
      currentlyRunning: {
        label: 'Currently running',
        option: {
          yes: 'Yes',
          no: 'No',
          all: 'All',
        }
      },
    }
  },
  notification: {
    overview: {
      title: 'Notifications',
      empty: 'There are no notifications matching this search filter',
      filter: {
        view: {
          label: 'Individual',
          value: {
            individual: 'Individual',
            combined: 'Combined',
          },
        },
      },
    },
    field: {
      type: {
        label: "Type",
        value: {
          email: 'E-mail',
          sms: 'SMS',
          whatsapp: 'WhatsApp',
          telegram: 'Telegram',
          voice: 'Voice',
          bundle: 'Bundle',
        }
      },
      status: {
        label: "Status",
        value: {
          initialization_pending: 'Initialization pending',
          initialization_failed: 'Initialization failed',
          delivery_pending: 'Pending',
          delivery_failed: 'Rejected',
          delivery_success: 'Delivered',
        },
      },
      scenario: {
        label: "Scenario",
        placeholder: "Click to select scenario"
      },
      receivers: {
        label: "Receivers",
      },
      dateSent: {
        label: "Date sent",
      },
      notificationText: {
        label: "Message",
      },
    }
  },
  simulation: {
    movements: '{{count}} movement performed.',
    movements_plural: '{{count}} movements performed.',
    active: '{{time}} active time.',
    modal: {
      title: 'Simulation Settings',
      date: { label: 'Date' },
      shuttles: {
        label: 'Shuttles',
        value: '{{count}} shuttle',
        value_plural: '{{count}} shuttles',
      },
      sameLayer: { label: 'Same Layer' },
      nearLeftToRight: { label: 'Near Left to Right' },
      aduEnabled: { label: 'ADU Enabled' },
      automaticReorganize: { label: 'Automatic Reorganizing' },
      simulateButton: 'Simulate',
    },
  },
  live: {
    tab: {
      processes: 'Processes',
      autoMove: 'Auto Moves',
      issues: 'Issues',
    },
    status: {
      moveButton: 'Move',
      enabled: 'Enabled',
      disabled: 'Disabled',
      manualCharge: 'Set to manual charge',
      autoCharge: 'Set to auto charge',
      tracyControlled: 'Controlled by Tracy',
    },
    selected: {
      container: 'Container {{id}}',
      shuttle: 'Shuttle {{id}}',
      lift: 'Lift {{id}}',
      block: 'Block {{id}}',
      bridge: 'Bridge {{id}}',
      machine: 'Machine {{id}}',
      push_pull: 'Push pull {{id}}',
      moveButton: 'Move',
      enableButton: 'Enable',
      disableButton: 'Disable',
      manualChargeButton: 'Manual Charge',
      autoChargeButton: 'Auto Charge',
      correctButton: 'Correct',
      forgetButton: 'Forget',
      seedingButton: 'Seed',
      transplantingButton: 'Transplant',
      spacing1Button: 'Space 1',
      spacing2Button: 'Space 2',
      harvestingButton: 'Harvest',
      setContainerButton: 'Set Container',
      scrapButton: 'Scrap',
    },
    blockButton: 'Block',
    moveModal: {
      title: 'Move',
      content: {
        preSubject: 'Are you sure you want to move ',
        subject: {
          container: 'container {{id}}',
          shuttle: 'shuttle {{id}}',
        },
        postSubjectPreLocation: ' to ',
        postLocation: '?',
      },
    },
    correctModal: {
      title: 'Correct',
      content: {
        preSubject: 'Are you sure you want to correct ',
        subject: 'container {{id}}',
        postSubjectPreLocation: ' to ',
        postLocation: '?',
      },
    },
    forgetModal: {
      title: 'Forget',
      content: {
        preSubject: 'Are you sure you want to forget ',
        subject: 'container {{id}}',
        postSubject: '?',
      },
    },
    performModal: {
      title: {
        seeding: 'Seeding',
        transplanting: 'Transplant',
        spacing1: 'Space 1',
        spacing2: 'Space 2',
        harvesting: 'Harvest',
      },
      content: {
        preSubject: {
          seeding: 'Are you sure you want to seed ',
          transplanting: 'Are you sure you want to transplant ',
          spacing1: 'Are you sure you want to space 1 ',
          spacing2: 'Are you sure you want to space 2 ',
          harvesting: 'Are you sure you want to harvest ',
        },
        postSubject: {
          seeding: '',
          transplanting: '',
          spacing1: '',
          spacing2: '',
          harvesting: '',
        },
        subject: 'container {{container}} (batch {{batch}})',
        preContainers: ' into ',
        container: 'container {{container}}',
        and: ' and ',
        postContainers: '?',
      },
    },
    setContainerModal: {
      title: 'Set Container',
      content: {
        preSubject: 'Are you sure you want to change the container of ',
        postSubjectPreContainer: ' to ',
        container: 'container {{container}}',
        postContainer: '?',
      },
    },
    scrapModal: {
      title: 'Scrap',
      content: {
        preSubject: 'Are you sure you want to scrap ',
        subject: 'container {{container}} (batch {{batch}})',
        postSubject: '?',
      },
    },
    selectProductionRequestModal: {
      title: 'Select Work Order',
      containers: 'Containers',
    },
    blockModal: {
      title: {
        block: 'Block',
        unblock: 'Unblock',
      },
      content: {
        preLocation: {
          block: 'Are you sure you want to block ',
          unblock: 'Are you sure you want to unblock ',
        },
        postLocation: {
          block: '?',
          unblock: '?',
        },
      },
      actions: {
        blockDirectly: 'Block directly',
        blockWithIssue: 'Block with issue',
        cancel: 'Cancel',
      },
    },
    toggleLeftToRightModal: {
      title: {
        leftToRight: 'Left to Right',
        rightToLeft: 'Right to Left',
      },
      content: {
        preRow: 'Are you sure you want to make ',
        postRow: {
          leftToRight: ' left to right?',
          rightToLeft: ' right to left?',
        },
      },
    },
    reorganizeModal: {
      title: 'Reorganize',
      content: {
        preRow: 'Are you sure you want to reorganize ',
        postRow: '?',
      },
    },
    cancelModal: {
      title: 'Cancel',
      content: {
        preDescription: 'Are you sure you want to cancel ',
        postDescription: '?',
      },
    },
    resolveModal: {
      title: 'Resolve',
      content: {
        preDescription: 'Are you sure you want to resolve ',
        postDescription: '?',
      },
    },
    lastSync: 'Last synced at {{lastSync}} ({{timePassed}} ago)',
    blockSelect: 'Selecting location to block...',
    leftToRightButton: 'Left to Right',
    reorganizeButton: 'Reorganize',
    toggleLeftToRightSelect: 'Selecting row to toggle left to right...',
    reorganizeSelect: 'Selecting row to reorganize...',
    containerSelect: 'Selecting container...',
    containerSelect_plural: 'Selecting container {{i}} of {{count}}...',
    moveSelect: {
      container: 'Selecting location to move container {{id}} to...',
      shuttle: 'Selecting location to move shuttle {{id}} to...',
    },
    correctSelect:  'Selecting location to correct container {{id}} to...',
    enableModal: {
      title: {
        enable: 'Enable',
        disable: 'Disable',
      },
      content: {
        preSubject: {
          enable: 'Are you sure you want to enable ',
          disable: 'Are you sure you want to disable ',
        },
        subject: 'shuttle {{id}}',
        postSubject: {
          enable: '?',
          disable: '?',
        },
      },
    },
    tracyControlModal: {
      title: {
        enable: 'Control by Tracy',
        disable: 'Control by DataControl',
      },
      content: {
        preSubject: {
          enable: 'Are you sure you want to let Tracy take control of ',
          disable: 'Are you sure you want to remove control of Tracy for ',
        },
        subject: 'machine {{id}}',
        postSubject: {
          enable: '?',
          disable: '?',
        },
      },
    },
    autoChargingModal: {
      title: {
        enable: 'Enable Auto Charging',
        disable: 'Disable Auto Charging',
      },
      content: {
        preSubject: {
          enable: 'Are you sure you want to enable auto charging for ',
          disable: 'Are you sure you want to disable auto charging for ',
        },
        subject: 'shuttle {{id}}',
        postSubject: {
          enable: '?',
          disable: '?',
        },
      },
    },
    process: {
      subject: {
        shuttle: 'Shuttle {{id}}',
        container: 'Container {{id}}',
      },
      event: {
        move: 'Move to {{location}}',
      },
      inProgress: 'In Progress',
      createdBy: 'Created by {{name}}.',
    },
    issue: {
      title: {
        shuttle: 'Issue with shuttle {{id}}',
        bridge: 'Issue with bridge {{id}}',
        lift: 'Issue with lift {{id}}',
      },
      resolveButton: 'Resolve',
    },
    legend: {
      leftToRight: 'Left to Right',
      pushPull: 'Push Pull',
      operationalModeAuto: 'In automatic mode',
      operationalModeNone: 'No operational mode data',
      alarmState: 'Active alarm',
    },
    forceSingleShuttle: 'Force Single Shuttle',
    forceSingleShuttleModal: {
      title: 'Force Single Shuttle',
      content: {
        preValue: 'Are you sure you want to turn force single shuttle ',
        value: { on: 'on', off: 'off' },
        postValue: '?',
      },
    },
    aduEnabled: 'ADUs Enabled',
    aduEnabledModal: {
      title: 'ADUs Enabled',
      content: {
        preValue: 'Are you sure you want to ',
        value: { on: 'enable', off: 'disable' },
        postValue: ' the ADUs?',
      },
    },
    automaticReorganize: 'Automatic Reorganizing',
    automaticReorganizeModal: {
      title: 'Automatic Reorganizing',
      content: {
        preValue: 'Are you sure you want to turn automatic reorganizing ',
        value: { on: 'on', off: 'uit' },
        postValue: '?',
      },
    },
    shuttleSelect: {
      label: 'Preferred Shuttle',
      value: 'Shuttle {{shuttleId}}',
    },
    reasonSelect: {
      label: 'Reason',
    },
    autoMove: {
      title: 'Auto move from {{location}} to {{destination}}',
      addButton: 'Add',
      locationSelect: 'Select start location...',
      destinationSelect: 'Select end location...',
    },
    addAutoMoveModal: {
      title: 'Add Auto Move',
      content: {
        preLocation: 'Are you sure you want to add an auto move from ',
        postLocationPreDestination: ' to ',
        postDestination: '?',
      },
    },
    deleteAutoMoveModal: {
      title: 'Delete Auto Move',
      content: {
        preLocation: 'Are you sure you want to delete the auto move from ',
        postLocationPreDestination: ' to ',
        postDestination: '?',
      },
    },
  },
  harvestTarget: {
    edit: {
      title: 'Harvest Targets',
      releaseButton: 'Release',
      allocationStatus: {
        calculating: 'Calculating... (Step 1/2)',
        finalizing: 'Finalizing... (Step 2/2)',
      },
    },
    field: {
      startDate: { label: 'From' },
      endDate: { label: 'Until' },
      target: { label: 'Weekly Harvest' },
      margin: { label: 'Safety Margin' },
      targetWithMargin: { label: 'Weekly Sow' },
      processes: { label: 'Processes' },
    },
  },
  cachedSimulation: {
    overview: {
      title: 'Simulation Stats',
      openProcesses: {
        title: 'Open Processes',
        field: {
          description: { label: 'Description' },
          status: { label: 'Status' },
        },
      },
    },
    field: {
      date: { label: 'Date' },
      activeTime: { label: 'Active Time' },
      movements: { label: '# Movements' },

      fullContainersStart: { label: '# Full Start' },
      emptyContainersStart: { label: '# Empty Start' },
      fullContainersEnd: { label: '# Full End' },
      emptyContainersEnd: { label: '# Empty End' },

      seedingInputTime : { label: 'Seed In' },
      seedingBusyTime : { label: 'Seed Busy' },
      seedingOutputTime : { label: 'Seed Out' },
    },
  },
  experiment: {
    field: {
      active: {
        label: 'Active',
        value: {
          active: 'Active',
          inactive: 'Inactive',
        },
      },
      experimentId: {
        label: 'ID',
      },
      experimentName: {
        label: 'Experiment',
      },
      cropType: {
        label: 'Type',
        value: {
          none: {
            title: 'No type selected',
          },
          leafy: {
            title: 'Leafy crop',
            subtitle: 'Chamber 1 to 10',
          },
          binding: {
            title: 'Viny crop',
            subtitle: 'Chamber 11 to 20',
          }
        }
      },
      chamber: {
        label: 'Chamber',
      },
      owner: {
        label: 'Owner',
      },
      status: {
        label: 'Status'
      },
      due: {
        label: 'Due date',
      },
      lastSync: {
        label: 'Last sync',
        value: {
          never: 'Never synced'
        },
      },
    },
    overview: {
      title: 'Experiments',
      filters: {
        experimentId: {
          label: 'Experiment ID',
        },
        experimentName: {
          label: 'Experiment name',
        },
        owner: {
          label: 'Owner',
        },
        chamber: {
          label: 'Chamber',
          placeholder: 'Select chamber',
        },
        due: {
          label: 'Due date',
        },
        cropType: {
          label: 'Type',
        },
        deleted: {
          label: 'Deleted experiments',
          value: {
            show: 'Show',
            hide: 'Hide',
          },
        },
        rejected: {
          label: 'Rejected measurements',
          value: {
            show: 'Show',
            hide: 'Hide',
          },
        }
      },
      buttons: {
        addMeasurement: {
          label: 'Add measurement',
        },
        edit: {
          label: 'Edit experiment',
        },
        view: {
          label: 'View experiment',
        },
        sync: {
          label: 'Sync experiment',
        },
      },
      toolbar: {
        sync: {
          label: 'Sync all experiments',
        },
        scan: {
          label: 'Scan barcode',
          modal: {
            title: 'Scan barcode',
            description: 'Scan the barcode of the experiment you want to view.',
            placeholder: 'Enter experiment ID here...',
            actions: {
              cancel: 'Cancel',
              scan: 'Scan',
            }
          }
        },
      },
    },
    edit: {
      title: 'Edit: [{{id}}] {{name}}',
    }
  },
  measurement: {
    field: {
      number: {
        label: 'No.',
      },
      status: {
        label: 'Status',
        value: {
          active: 'Active',
          rejected: 'Rejected',
        },
      },
      timestamp: {
        label: 'Timestamp',
      },
      author: {
        label: 'Added by',
      },
      remarks: {
        label: 'Remarks',
        placeholder: 'Type comments here...',
      },
    },
    overview: {
      measurementAmount: {
        label: 'Measurements ({{amount}})',
      },
      buttons: {
        view: {
          label: 'View measurement',
        },
        reject: {
          reject: 'Reject measurement',
          unreject: 'Undo rejection',
        },
      },
    },
    edit: {
      title: {
        add: 'Add measurement: [{{id}}] {{name}}',
        view: 'View measurement: [{{id}}] {{name}}',
      },
      warning: {
        title: 'Warning',
        description: 'An added measurement can not be edited, only rejected.',
      },
      treatment: 'Treatment: {{name}}',
      buttons: {
        reject: {
          reject: 'Reject measurement',
          unreject: 'Undo rejection',
        },
        cancel: {
          label: 'Cancel'
        }
      },
    },
  },
  rowBlock: {
    create: {
      title: 'Add row block',
    },
    edit: {
      title: 'Edit row block',
    },
    field: {
      layer: { label: 'Layer' },
      rack: { label: 'Rack' },
      startDate: { label: 'From' },
      endDate: { label: 'To' },
      plantationLine: { label: 'Plantation line' },
    }
  },
}
